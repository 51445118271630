import { createContext, useContext, useEffect, useState } from "react";

import Auth from "../Auth";
import { API_VERSION, BACKEND_URL } from "../config";
import { toISODateString } from "../utils";

const OrdersContext = createContext({
  orders: [],
  loading: false,
  searchValue: "",
  status: "",
  pageNum: 0,
  pageSize: 10,
  totalCount: 0,
  startDate: null,
  endDate: null,
  sortParams: {},
  refreshOrders: () => {},
  searchOrders: () => {},
});

export const OrdersProvider = ({ children }) => {
  const [orders, setOrders] = useState([]);

  const [pageNum, setPageNum] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const [searchValue, setSearchValue] = useState("");
  const [status, setStatus] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sortParams, setSortParams] = useState({});

  const [loading, setLoading] = useState(false);

  const getAllOrders = (search, status, pageNum, pageSize, startDate, endDate) => {
    setLoading(true);
    Auth.client
      .get(`${BACKEND_URL}/${API_VERSION}/vendor-orders`, {
        params: {
          search: search || null,
          txn_status: status && status.length > 0 ? status.join(",") : null,
          page: pageNum ? pageNum + 1 : 1,
          page_size: pageSize,
          start_date: startDate ? toISODateString(startDate) : null,
          end_date: endDate ? toISODateString(endDate) : null,
          ...sortParams,
        },
      })
      .then((res) => {
        // console.log(res);
        if (res && res.data) {
          setOrders(
            res.data.results.map((transaction) => ({
              ...transaction,
              id: transaction.transaction_id,
              customer_name: transaction.customer.customer_name,
            }))
          );
          setTotalCount(res.data.count);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAllOrders();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getAllOrders(searchValue, status, pageNum, pageSize, startDate, endDate);
    // eslint-disable-next-line
  }, [sortParams, status, pageNum, pageSize, startDate, endDate]);

  const refreshOrders = () => {
    getAllOrders(searchValue, status, pageNum, pageSize, startDate, endDate);
  };

  const searchOrders = (search) => {
    getAllOrders(search);
  };

  return (
    <OrdersContext.Provider
      value={{
        orders,
        loading,
        totalCount,
        searchValue,
        setSearchValue,
        status,
        setStatus,
        pageNum,
        setPageNum,
        pageSize,
        setPageSize,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        sortParams,
        setSortParams,
        refreshOrders,
        searchOrders,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};

const useOrdersContext = () => {
  return useContext(OrdersContext);
};

export default useOrdersContext;
