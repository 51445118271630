import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  cartItem: {
    display: "flex",
    gap: theme.spacing(2),
    margin: theme.spacing(1, 0),
  },
  cartItemImage: {
    width: "30%",
    maxHeight: 150,
    objectFit: "cover",
  },
  cartItemDetails: {
    width: "60%",
    flex: 1,
  },
  indent: {
    paddingLeft: theme.spacing(2),
  },
}));

const CartItem = ({ item }) => {
  const classes = useStyles();

  if (!item.product) {
    return <div className={classes.cartItem} />;
  }

  return (
    <div className={classes.cartItem}>
      <img
        className={classes.cartItemImage}
        src={item.product.images && item.product.images[0].image}
        alt={item.product.description}
      />
      <div className={classes.cartItemDetails}>
        <Typography variant="body2">
          <b>Name:</b> {item.product.title}
        </Typography>
        <Typography variant="body2">
          <b>Quantity:</b> {item.quantity}
        </Typography>

        {item.meta ? (
          <Fragment>
            <Typography variant="body2">
              <b>Responses:</b>
            </Typography>

            {item.meta.map((question, i) => (
              <Fragment key={i}>
                <Typography variant="body2">{question.question_title}</Typography>
                {question.responses &&
                  question.responses.map((r, i) => (
                    <Typography key={i} color="textSecondary" variant="body2" className={classes.indent}>
                      - {r}
                    </Typography>
                  ))}
              </Fragment>
            ))}
          </Fragment>
        ) : (
          <Fragment>
            <Typography variant="body2">
              <b>Options:</b>
            </Typography>
            {item.options.length > 0 ? (
              item.options.map((option, i) => (
                <Typography key={i} color="textSecondary" variant="body2" className={classes.indent}>
                  - {option.text}
                </Typography>
              ))
            ) : (
              <Typography color="textSecondary" variant="body2">
                No options selected
              </Typography>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default CartItem;
