export const getPercentageDiscount = (originalPrice, discountedPrice) => {
  if (originalPrice && discountedPrice) {
    const discount = ((originalPrice - discountedPrice) / originalPrice) * 100;
    return -1 * discount.toFixed(0);
  }
  return "";
};

export const getLowestPrice = (product) => {
  const minPrice =
    product.variants &&
    product.variants.length > 0 &&
    product.variants.reduce((a, b) => ({ price: Math.min(parseFloat(a.price), parseFloat(b.price)) }), {
      price: Number.MAX_VALUE,
    });
  return minPrice ? parseFloat(minPrice.price).toFixed(2) : "";
};

export const variantOnDiscount = (product) => {
  const haveDiscountedVariants = product && product.variants.some((variant) => variant.original_price !== null);
  if (haveDiscountedVariants) {
    return true;
  }
  return false;
};
