import React, { useState } from "react";
import { withStyles, useTheme } from "@material-ui/core/styles"; // eslint-disable-next-line
import { Paper, Typography, Button, Fab } from "@material-ui/core";
import { DropzoneAreaBase } from "material-ui-dropzone";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Edit } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";

import Auth from "../../../Auth";
import { BACKEND_URL } from "../../../config";
import EditImageDialog from "./EditImageDialog";
import { addImagesToProduct, updateImage } from "../api";

const styles = (theme) => ({
  header: {
    fontWeight: 400,
    margin: theme.spacing(1, 0, 1),
  },
  subheader: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    borderRadius: "10px",
    height: "auto",
    padding: theme.spacing(3),
  },
  titleBar: {
    background: "transparent",
  },
  uploadContainer: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "flex-end",
  },
  dropzoneContainer: {
    padding: theme.spacing(3),
    minHeight: 100,
  },
  dropzoneParagraph: {
    fontSize: 14,
  },
  imageContainer: {
    display: "flex",
    overflow: "auto",
    padding: theme.spacing(1, 0),
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  imageItem: {
    userSelect: "none",
    margin: theme.spacing(1),
    // width: "100%",
    height: "120px",
    position: "relative",
  },
  image: {
    objectFit: "contain",
    height: "120px",
  },
  editButton: {
    position: "absolute",
    top: -12,
    right: -9,
    height: 36, // there is min height 36px
    width: 35,
  },
  coverTypographyOverlay: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    textAlign: "center",
    background: "rgba(0, 0, 0, 0.4)",
    color: theme.palette.common.white,
  },
});

const ProductImages = (props) => {
  const { classes, images, setImages, formIsHalfFilled, setFormIsHalfFilled, product, edit, loading } = props;

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const [dialogOpen, setDialogOpen] = useState(false);
  const [image, setImage] = useState({ image_id: "", image: "", image_caption: "" });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (edit) {
      const newImageArr = reorder(images, result.source.index, result.destination.index);
      setImages(newImageArr);
      reorderImages(newImageArr);
    } else {
      setImages(reorder(images, result.source.index, result.destination.index));
    }
  };

  const reorderImages = (result) => {
    const imageIdArr = result.map((image) => image.image_id);

    Auth.client
      .patch(`${BACKEND_URL}/v1/products/${product.product_id}/reorder-images`, imageIdArr)
      .then((res) => {
        // console.log(res);
        // setImages(res.data);
      })
      .catch((err) => console.log(err));
  };

  const deleteImage = (id) => {
    Auth.client
      .delete(`${BACKEND_URL}/v1/products/${product.product_id}/images/${id}`)
      .then((res) => {
        // console.log(res);
        setImages(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const uploadImage = (img) => {
    const formData = new FormData();
    formData.append("image", img[0].file);
    formData.append("image_captions", [""]);

    addImagesToProduct(product.product_id, formData)
      .then((res) => {
        // console.log(res);
        setImages(res.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const handleUpdateImage = (image) => {
    if (edit) {
      updateImage(product, image)
        .then((res) => {
          setDialogOpen(false);
          setImages(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const idx = images.findIndex((img) => img.data === image.data && img.file === image.file);
      let duplicateImages = [...images];
      duplicateImages[idx] = image;
      setImages(duplicateImages);
      setDialogOpen(false);
    }
  };

  const handleAddImage = (newPhoto) => {
    if (edit) {
      uploadImage(newPhoto);
    } else {
      setImages(images.concat(newPhoto));
    }

    if (!formIsHalfFilled) {
      setFormIsHalfFilled(true);
    }
  };

  const handleDeleteImage = (image) => {
    if (edit) {
      deleteImage(image.image_id);
    } else {
      setImages(images.filter((photo) => photo !== image));
    }

    if (!formIsHalfFilled) {
      setFormIsHalfFilled(true);
    }
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // border: isDragging ? `3px solid #FFC000` : "none",
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#FFF2D2" : "transparent",
  });

  return (
    <Paper elevation={3} className={classes.paper}>
      <Typography variant="h5" className={classes.header}>
        Images
      </Typography>
      <Typography variant="body2" color="textSecondary" className={classes.subheader}>
        Great images help you sell better. For the best results, we recommend an aspect ratio of{" "}
        <span style={{ fontStyle: "italic" }}>1:1</span>
      </Typography>
      <DropzoneAreaBase
        dropzoneText={
          <div>
            Drop images here or{" "}
            <Button variant="contained" color="primary">
              Upload Photos
            </Button>
          </div>
        }
        alertSnackbarProps={{
          anchorOrigin: { vertical: "top", horizontal: "right" },
        }}
        dropzoneParagraphClass={classes.dropzoneParagraph}
        dropzoneClass={classes.dropzoneContainer}
        acceptedFiles={["image/*"]}
        filesLimit={10}
        onAdd={(newPhoto) => handleAddImage(newPhoto)}
        showPreviewsInDropzone={false}
        fileObjects={images}
        maxFileSize={10000000} // allow up to 10MB
      />
      {loading ? (
        <div className={classes.imageContainer}>
          <div className={classes.imageItem}>
            <Skeleton animation="wave" width={180} height={"100%"} />
          </div>
        </div>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction={smDown ? "vertical" : "horizontal"}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                className={classes.imageContainer}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                {images &&
                  images.map((image, index) => (
                    <Draggable
                      key={edit ? image.image_id : `${image.file.name}-${index}`}
                      draggableId={edit ? image.image_id : `${image.file.name}-${index}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          className={classes.imageItem}
                        >
                          <img className={classes.image} src={edit ? image.image : image.data} alt={index} />
                          <Fab
                            disableRipple
                            className={classes.editButton}
                            color="primary"
                            onClick={() => {
                              setImage(image);
                              setDialogOpen(true);
                            }}
                          >
                            <Edit color="inherit" style={{ fontSize: "1rem" }} />
                          </Fab>
                          {index === 0 && (
                            <Typography className={classes.coverTypographyOverlay} variant="body2">
                              Cover
                            </Typography>
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      <Typography variant="body2">Drag images to reorder them</Typography>
      <EditImageDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        image={image}
        setImage={setImage}
        updateImage={handleUpdateImage}
        deleteImage={handleDeleteImage}
        edit={edit}
      />
    </Paper>
  );
};

export default withStyles(styles)(ProductImages);
