import React from "react";

import svg from "../assets/AnimatedLogo.svg";

const AnimatedLogo = ({ style }) => {
  return (
    <object type="image/svg+xml" data={svg} alt="loading" style={{ ...style }}>
      Loading...
    </object>
  );
};

export default AnimatedLogo;
