import Auth from "../../../Auth";
import { BACKEND_URL, API_VERSION } from "../../../config";
import { startOfDay } from "date-fns";

export const getVendorPromoCodes = () => {
  return Auth.client.get(`${BACKEND_URL}/${API_VERSION}/promo-codes`);
};

export const createPromoCode = (obj) => {
  const promo_code = { ...obj, start_date: startOfDay(obj.start_date) };
  return Auth.client.post(`${BACKEND_URL}/${API_VERSION}/promo-codes`, promo_code);
};

export const editPromoCode = (obj) => {
  const promo_code = { ...obj, start_date: startOfDay(obj.start_date) };
  return Auth.client.patch(`${BACKEND_URL}/${API_VERSION}/promo-codes/${promo_code.id}`, promo_code);
};

export const deletePromoCode = (deletePromoId) => {
  return Auth.client.delete(`${BACKEND_URL}/${API_VERSION}/promo-codes/${deletePromoId}`);
};

export const updatePromoCodeStatus = (promoCode) => {
  return Auth.client.patch(`${BACKEND_URL}/${API_VERSION}/promo-codes/${promoCode.promo_code_id}`, {
    active: !promoCode.active,
  });
};
