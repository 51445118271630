import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { viewSubscriptionOld } from "./api";
import { Button } from "@material-ui/core";
import GenericContainer from "../../components/containers/GenericContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 190px)", // padding: 30px * 2, title: 80px + 50px, total 190px
    margin: 30,
  },
}));

const SubscriptionOld = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Fragment>
        <GenericContainer>
          <div className={classes.body}>
            <div className={classes.actionSection}>
              <Button
                className={classes.primaryButton}
                variant="contained"
                color="primary"
                onClick={() => viewSubscriptionOld()}
              >
                Manage Subscription
              </Button>
            </div>
          </div>
        </GenericContainer>
      </Fragment>
    </div>
  );
};

export default SubscriptionOld;
