import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import PageTitle from "../../components/PageTitle";
import DeliverySettings from "./containers/DeliverySettings";
import Schedule from "./containers/Schedule";
import CollectionSettings from "./containers/CollectionSettings";
import { fetchVendorFulfillmentInfo } from "./api";
import { Helmet } from "react-helmet";
import { fetchProducts } from "../ProductList/api";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 30,
  },
}));

const Delivery = (props) => {
  const classes = useStyles();

  const [vendor, setVendor] = useState({
    days_in_advance: 0,
    delivery: false,
    collection: false,
    collection_options: [],
    delivery_options: [],
    blocked_dates_delivery: [],
    blocked_week_days_delivery: {
      0: false,
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
    },
    blocked_dates_self_collection: [],
    blocked_week_days_self_collection: {
      0: false,
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
    },
    max_date_range: 120,
  });

  const [products, setProducts] = useState([]);

  const fetchData = () => {
    fetchVendorFulfillmentInfo().then((res) => {
      setVendor(res.data);
    });

    fetchProducts({ active: true }).then((res) => {
      setProducts(res.data);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Delivery | Carte</title>
      </Helmet>
      <PageTitle title="Delivery/Collection" />
      <Schedule vendor={vendor} setVendor={setVendor} {...props} />
      <DeliverySettings vendor={vendor} setVendor={setVendor} products={products} {...props} />
      <CollectionSettings vendor={vendor} setVendor={setVendor} products={products} {...props} />
    </div>
  );
};

export default Delivery;
