import { Divider, InputAdornment, Switch, TextField, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { HelpOutline } from "@material-ui/icons";
import React from "react";

import GenericContainer from "../../../components/containers/GenericContainer";

import useAuth from "../../../hooks/useAuth";
import useShopContext from "../../../hooks/useShopContext";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 400,
    margin: theme.spacing(1, 0, 1),
  },
  textField: {
    margin: theme.spacing(1, 0),
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[3],
    fontWeight: "normal",
  },
  infoIcon: {
    marginLeft: "8px",
    color: "grey",
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  toggleSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const ShopGeneral = () => {
  const classes = useStyles();
  const { user: vendor, setUser: setVendor } = useAuth();
  const { shopfront, setShopfront } = useShopContext();

  return (
    <GenericContainer>
      <Typography variant="h5" className={classes.header}>
        General Settings
      </Typography>
      <TextField
        variant="outlined"
        margin="dense"
        autoComplete="off"
        fullWidth
        name="title"
        label="URL"
        required
        classes={{ root: classes.textField }}
        InputProps={{
          endAdornment: <InputAdornment position="end">.carteapp.io</InputAdornment>,
        }}
        onChange={(e) => setVendor({ ...vendor, subdomain: e.target.value })}
        value={vendor.subdomain ? vendor.subdomain : ""}
      />

      <TextField
        variant="outlined"
        margin="dense"
        autoComplete="off"
        fullWidth
        name="title"
        label="Shop Name"
        classes={{ root: classes.textField }}
        onChange={(e) => setShopfront({ ...shopfront, title: e.target.value })}
        value={shopfront.title}
      />

      <div style={{ display: "flex", alignItems: "center" }}>
        <TextField
          id="tnc"
          variant="outlined"
          margin="dense"
          autoComplete="off"
          type="text"
          name="tnc"
          label="Terms & Conditions"
          placeholder="Enter link"
          style={{ flex: 1 }}
          value={vendor.tnc ? vendor.tnc : ""}
          onChange={(e) => setVendor({ ...vendor, tnc: e.target.value })}
          classes={{ root: classes.textField }}
        />
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          enterTouchDelay={250}
          title={
            <Typography variant="body2">
              (Optional) Add a link to your terms and conditions, we will require the customer to agree before purchase
            </Typography>
          }
          placement="bottom-end"
        >
          <HelpOutline className={classes.infoIcon} />
        </Tooltip>
      </div>

      <Divider className={classes.divider} />

      <div className={classes.toggleSection}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography>Deactivate Shop</Typography>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            enterTouchDelay={250}
            title={<Typography variant="body2">To stop receiving orders</Typography>}
            placement="right"
          >
            <HelpOutline className={classes.infoIcon} />
          </Tooltip>
        </div>

        <Switch
          checked={!shopfront.is_active}
          onChange={(e) => setShopfront({ ...shopfront, is_active: !e.target.checked })}
          color="primary"
        />
      </div>

      <div className={classes.toggleSection}>
        <Typography>Festive Features</Typography>
        <Switch
          checked={vendor.enable_seasonal_features || false}
          onChange={(e) =>
            setVendor({
              ...vendor,
              enable_seasonal_features: e.target.checked,
            })
          }
          color="primary"
        />
      </div>
    </GenericContainer>
  );
};

export default ShopGeneral;
