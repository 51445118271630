import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Button, CircularProgress, Paper, TextField, Typography, makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";

import useQuery from "../hooks/useQuery";
import { resetPassword, sendResetPasswordLink } from "../Auth";
import StyledTypography from "../components/StyledTypography";

const useStyles = makeStyles((theme) => ({
  main: {
    minHeight: "100vh",
    backgroundColor: theme.palette.background.dark,
    display: "grid",
    placeItems: "center",
  },
  paperRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gap: theme.spacing(1),
    margin: theme.spacing(1),
    padding: theme.spacing(4, 2),
    width: "40vw",
    [theme.breakpoints.down("sm")]: {
      width: "85vw",
    },
  },
  paperBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gap: theme.spacing(0.5),
    margin: theme.spacing(3, 0),
    width: 350,
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  input: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.dark,
    borderRadius: "32px",
    border: `1px solid ${theme.palette.grey[400]}`,
    fontSize: 18,
    padding: theme.spacing(1, 2),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      fontSize: 17,
      padding: "12px 10px",
    },
  },
  button: {
    borderRadius: 15,
    width: "100%",
    textTransform: "capitalize",
    margin: "10px 0",
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
  },
}));

const ResetPasswordPage = (props) => {
  const classes = useStyles();
  const query = useQuery();
  const { enqueueSnackbar } = useSnackbar();

  const jwt = query.get("t");

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (jwt) {
      if (password !== password2) {
        enqueueSnackbar("Passwords do not match", {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
        });
        setLoading(false);
        return;
      }

      // make authenticated call to reset password
      resetPassword(jwt, password)
        .then((res) => {
          enqueueSnackbar("Password reset successful", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
          });
          setPassword("");
          setPassword2("");
        })
        .catch((err) =>
          enqueueSnackbar("Something went wrong. Please try again!", {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
          })
        )
        .finally(() => setLoading(false));
      return;
    }

    // send password reset link
    sendResetPasswordLink(email)
      .then((res) =>
        enqueueSnackbar("Password Reset link sent to your email!", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
        })
      )
      .catch((err) =>
        enqueueSnackbar("Something went wrong. Please try again!", {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
        })
      )
      .finally(() => setLoading(false));
  };

  return (
    <Fragment>
      <div className={classes.main}>
        <form onSubmit={handleSubmit}>
          <Paper elevation={3} className={classes.paperRoot}>
            <div>
              <Typography variant="h1" style={{ fontWeight: 500, margin: "8px 0" }}>
                <StyledTypography>Password Reset</StyledTypography>
              </Typography>
              <Typography variant="h6" color="textSecondary">
                {jwt ? "Reset your password" : "No worries, we'll send you a link to reset your password"}
              </Typography>
            </div>

            <div className={classes.paperBody}>
              {jwt ? (
                <Fragment>
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    required
                  />
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    placeholder="Re-enter Password"
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                    type="password"
                    required
                  />
                </Fragment>
              ) : (
                <TextField
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  required
                />
              )}

              <Button variant="contained" color="primary" className={classes.button} disabled={loading} type="submit">
                {loading ? <CircularProgress size="1.5rem" color="inherit" /> : "Reset Password"}
              </Button>

              <Typography variant="body2">
                <Link to="/login" className={classes.link}>
                  Back to login
                </Link>
              </Typography>
            </div>
          </Paper>
        </form>
      </div>
    </Fragment>
  );
};

export default ResetPasswordPage;
