export const ACCEPTED_CURRENCIES = [
  "sgd",
  "usd",
  "aud",
  "bnd",
  "cny",
  "eur",
  "gbp",
  "hkd",
  "idr",
  "jpy",
  "khr",
  "krw",
  "mmk",
  "mop",
  "myr",
  "npr",
  "php",
  "thb",
  "twd",
  "vnd",
];

export const CURRENCY_PREFIX_MAP = {
  sgd: "S$",
  usd: "US$",
  aud: "A$",
  bnd: "B$",
  cny: "CN¥",
  eur: "€",
  gbp: "£",
  hkd: "HK$",
  idr: "Rp",
  jpy: "JP¥",
  khr: "៛",
  krw: "KR₩",
  mmk: "K",
  mop: "MOP$",
  myr: "RM",
  npr: "Rs",
  php: "PH₱",
  thb: "TH฿",
  twd: "NT$",
  vnd: "₫",
};

export const prefixCurrency = (price, vendor = {}) => {
  return `${CURRENCY_PREFIX_MAP[vendor.currency]}${price}`;
};
