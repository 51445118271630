import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, Grid, Hidden } from "@material-ui/core";
import { Prompt, useHistory } from "react-router-dom";
import { Add } from "@material-ui/icons";
import { Helmet } from "react-helmet";
import { useSnackbar } from "notistack";

import ProductDetails from "./components/ProductDetails";
import ProductVariants from "./components/ProductVariants";
import ProductImages from "./components/ProductImages";
import AdditionalQuestions from "./components/AdditionalQuestions";
import ProductPreview from "./components/ProductPreview";
import PageTitle from "../../components/PageTitle";

import { addImagesToProduct, createProduct, updateProductVariant } from "./api";
import useAuth from "../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 30,
  },
  submitButton: {
    textTransform: "none",
    margin: theme.spacing(0, 1),
  },
  header: {
    fontWeight: 600,
    margin: theme.spacing(2, 0),
  },
  gridItem: {
    maxWidth: "100%",
  },
  lastGridItem: {
    marginBottom: 40,
  },
  footer: {
    position: "fixed",
    bottom: 0,
    right: 0,
    height: 50,
    background: theme.palette.common.white,
    width: "100%",
    boxShadow: "0px 3px 15px 0px rgba(0,0,0,0.3)",
    WebkitBoxShadow: "0px 3px 15px 0px rgba(0,0,0,0.3)",
    zIndex: 10,
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(1, 2),
  },
}));

const CreateProduct = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { user: vendor } = useAuth();

  const [product, setProduct] = useState({
    title: "",
    description: "",
    inventory: null,
    original_price: null,
    show_percentage_discount: false,
    price: "",
    product_questions: [],
    use_variants: false,
    variants: [],
    available_start_time: null,
    available_end_time: null,
  });

  const [images, setImages] = useState([]);
  const [formIsHalfFilled, setFormIsHalfFilled] = useState(false);
  const [creatingProduct, setCreatingProduct] = useState(false);

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCreatingProduct(true);

    if (!product.title || !product.description) {
      enqueueSnackbar("Missing name or description", { variant: "error" });
      setCreatingProduct(false);
      return;
    }

    if (images.length === 0) {
      enqueueSnackbar("Upload at least a photo. Photos sell.", { variant: "error" });
      setCreatingProduct(false);
      return;
    }

    if (parseFloat(product.price) <= 0 || product.price === "") {
      enqueueSnackbar("Invalid price", { variant: "error" });
      setCreatingProduct(false);
      return;
    }

    if (product.original_price !== null && product.original_price === "") {
      enqueueSnackbar("Original price should not be empty", { variant: "error" });
      setCreatingProduct(false);
      return;
    }

    if (product.original_price !== null && parseFloat(product.original_price) <= parseFloat(product.price)) {
      enqueueSnackbar("Original price should be higher", { variant: "error" });
      setCreatingProduct(false);
      return;
    }

    if (product.inventory && parseInt(product.inventory) <= 0) {
      enqueueSnackbar("Invalid inventory", { variant: "error" });
      setCreatingProduct(false);
      return;
    }

    if (product.available_end_time && product.available_start_time) {
      if (product.available_end_time <= product.available_start_time) {
        enqueueSnackbar("End time has to be later than start time", { variant: "error" });
        setCreatingProduct(false);
        return;
      }
    }

    if (product.use_variants && product.variants.length <= 1) {
      enqueueSnackbar("Set minimum 2 variants", { variant: "error" });
      setCreatingProduct(false);
      return;
    }

    setFormIsHalfFilled(false);

    // call create product endpoint
    let productId;
    let errorMsg;

    await createProduct(product)
      .then((res) => {
        // console.log(res);
        productId = res.data.product_id;
      })
      .catch((err) => {
        // console.log(err);
        errorMsg = err.response.data || "Error creating product";
      });

    if (!errorMsg && images.length > 0) {
      const formData = new FormData();
      images.forEach((img) => {
        formData.append("image", img.file);
        formData.append("image_captions", img.image_caption ? img.image_caption : "");
      });

      await addImagesToProduct(productId, formData)
        .then((result) => {
          // console.log(res);
        })
        .catch((err) => {
          console.log(err);
          errorMsg = "Error uploading photos";
        });
    }

    if (!errorMsg && product.use_variants) {
      if (product.variants.length <= 1) {
        enqueueSnackbar("Set minimum 2 variants", { variant: "error" });
        return;
      }

      await updateProductVariant(productId, product, vendor)
        .then((res) => {
          // console.log(res);
        })
        .catch((err) => {
          errorMsg = err.response.data || "Error updating variant";
        });
    }

    enqueueSnackbar(errorMsg ? `${errorMsg}, please try again or DM us on instagram for help` : `Product created`, {
      variant: errorMsg ? "error" : "success",
    });
    if (!errorMsg) {
      history.push("/home/products");
    } else {
      setCreatingProduct(false);
    }
  };

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
    if (formIsHalfFilled) {
      // covers for situations when history.action is POP or PUSH
      // PUSH means entering create product page from other pages
      // POP means when user clicks on browser refresh button
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [formIsHalfFilled, history.action]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Create Product | Carte</title>
      </Helmet>
      <Prompt
        when={formIsHalfFilled}
        message={() => {
          return "Changes you made may not be saved. Continue to leave site?";
        }}
      />
      <form onSubmit={handleSubmit}>
        <PageTitle title="New Product" style={{ margin: 8 }} />
        <Grid container spacing={2} justify="center">
          <Grid container item xs={12} md={6} direction="column" spacing={3}>
            <Grid className={classes.gridItem} item>
              <ProductDetails
                product={product}
                setProduct={setProduct}
                formIsHalfFilled={formIsHalfFilled}
                setFormIsHalfFilled={setFormIsHalfFilled}
              />
            </Grid>

            <Grid className={classes.gridItem} item>
              <ProductImages
                images={images}
                setImages={setImages}
                formIsHalfFilled={formIsHalfFilled}
                setFormIsHalfFilled={setFormIsHalfFilled}
                product={product}
                setProduct={setProduct}
              />
            </Grid>

            <Grid className={classes.gridItem} item>
              <ProductVariants product={product} setProduct={setProduct} enqueueSnackbar={enqueueSnackbar} />
            </Grid>

            <Grid className={`${classes.gridItem} ${classes.lastGridItem}`} item>
              <AdditionalQuestions
                product={product}
                setProduct={setProduct}
                formIsHalfFilled={formIsHalfFilled}
                setFormIsHalfFilled={setFormIsHalfFilled}
                enqueueSnackbar={enqueueSnackbar}
              />
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid item md={6}>
              <ProductPreview product={product} images={images} />
            </Grid>
          </Hidden>
        </Grid>
        <div className={classes.footer}>
          {creatingProduct ? (
            <Button
              className={classes.submitButton}
              color="primary"
              variant="contained"
              startIcon={<CircularProgress size="1.5rem" color="inherit" />}
            >
              Creating Product
            </Button>
          ) : (
            <Button
              className={classes.submitButton}
              color="primary"
              variant="contained"
              type="submit"
              startIcon={<Add />}
            >
              Create Product
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

export default CreateProduct;
