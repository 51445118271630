import React, { Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { withStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, Paper, TextField, Typography } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";
import _ from "lodash";

import useAuth from "../hooks/useAuth";
import { FRONTEND_URL } from "../config";
import StyledTypography from "../components/StyledTypography";

const styles = (theme) => ({
  main: {
    minHeight: "100vh",
    backgroundColor: theme.palette.background.dark,
    display: "grid",
    placeItems: "center",
  },
  paperRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gap: theme.spacing(1),
    margin: theme.spacing(1),
    padding: theme.spacing(4, 2),
    width: "40vw",
    [theme.breakpoints.down("sm")]: {
      width: "85vw",
    },
  },
  paperBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gap: theme.spacing(0.5),
    margin: theme.spacing(3, 0),
    width: 350,
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  input: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.dark,
    borderRadius: "32px",
    border: `1px solid ${theme.palette.grey[400]}`,
    width: "100%",
    fontSize: 18,
    padding: "8px 15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 17,
      padding: "12px 10px",
    },
  },
  button: {
    borderRadius: 15,
    width: "100%",
    textTransform: "capitalize",
    margin: "10px 0",
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
  },
});

const LoginPage = (props) => {
  const { classes } = props;
  const history = useHistory();

  const { login, error, user, loading } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
  });

  const handleEmailChange = (e) => {
    setLoginDetails({
      ...loginDetails,
      email: e.target.value,
    });
  };

  const handlePasswordChange = (e) => {
    setLoginDetails({
      ...loginDetails,
      password: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await login({ ...loginDetails, email: loginDetails.email.toLowerCase() });
  };

  // push to dashboard
  useEffect(() => {
    if (Cookies.get("t1")) {
      // console.log("logged in");
      history.push("/");
    }
  }, [history]);

  // when context updates
  useEffect(() => {
    if (!_.isEmpty(user) && !error) {
      history.push("/home/shop");
    }
    if (error) {
      if (error.response && error.response.status === 401) {
        enqueueSnackbar("Incorrect email address or password. Please try again!", {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
        });
      } else {
        enqueueSnackbar(
          `Something went wrong. Kindly contact us with the error code: ${error.response && error.response.status}`,
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, error]);

  return (
    <Fragment>
      <Helmet>
        <title>Login | Carte</title>
        <link rel="canonical" href={`${FRONTEND_URL}`} />
      </Helmet>
      <div className={classes.main}>
        <form onSubmit={handleSubmit}>
          <Paper elevation={3} className={classes.paperRoot}>
            <Typography variant="h1" style={{ fontWeight: 500, marginTop: "12px" }}>
              <StyledTypography>LOGIN</StyledTypography>
            </Typography>
            <Typography variant="h6" gutterBottom>
              Welcome back, we've missed you!
            </Typography>

            <div className={classes.paperBody}>
              <TextField
                fullWidth
                margin="dense"
                variant="outlined"
                placeholder="Email"
                value={loginDetails && loginDetails.email}
                onChange={handleEmailChange}
                type="email"
                required
                autoFocus
              />

              <TextField
                fullWidth
                margin="dense"
                variant="outlined"
                placeholder="Password"
                value={loginDetails && loginDetails.password}
                onChange={handlePasswordChange}
                type="password"
                required
              />

              <Button variant="contained" color="primary" className={classes.button} type="submit">
                {loading ? <CircularProgress size="1.5rem" color="inherit" /> : "Login"}
              </Button>

              <div style={{ maxWidth: "90%" }}>
                <Typography variant="body2">
                  <Link to="/reset-password" className={classes.link}>
                    Forgot password
                  </Link>
                </Typography>
              </div>

              <Typography variant="body1" color="textSecondary" style={{ marginTop: 24 }}>
                Do not have an account? Click{" "}
                <Link to="/register" className={classes.link}>
                  here
                </Link>{" "}
                to register!
              </Typography>
            </div>
          </Paper>
        </form>
      </div>
    </Fragment>
  );
};

export default withStyles(styles)(LoginPage);
