import { createContext, useContext, useEffect, useState } from "react";

import Auth from "../Auth";
import { API_VERSION, BACKEND_URL } from "../config";

const ShopContext = createContext({
  shopfront: {},
  shopfronts: [],
  updateShopfronts: () => {},
  refreshShopfront: () => {},
});

export const ShopProvider = ({ children }) => {
  const [shopfront, setShopfront] = useState({
    shopfront_type: "shop",
    layout: "column",
    background_color: "#FFFBF6",
    accent_color: "#8B5725",
    title: "",
    description: "",
    is_active: true,
    display_logo: false,
    logo_size: "regular",
    background_image: null,
    use_background_image: false,
    use_welcome_page: false,
    use_categories: false,
    meta_tag_content: "",
    meta_pixel_id: "",
    meta_tag_is_active: false,
    text_background_opacity: 0.8,
    text_color: "#000000",
    reorder_soldout_products: false,
    bordered: true,
    geo_blocked: false,
    restricted_countries: [],
    enter_button_text: "",
  });
  const [shopfronts, setShopfronts] = useState([]);
  const [loading, setLoading] = useState(true);

  const getShopfront = () => {
    Auth.client.get(`${BACKEND_URL}/${API_VERSION}/shopfronts`).then((res) => {
      const currentShop = res.data[0];
      if (currentShop) {
        setShopfront({
          shopfront_id: currentShop.shopfront_id,
          shopfront_type: currentShop.shopfront_type,
          layout: currentShop.layout,
          background_color: currentShop.background_color || "#FFFBF6",
          accent_color: currentShop.accent_color || "#8B5725",
          title: currentShop.title,
          description: currentShop.description,
          is_active: currentShop.is_active,
          display_reviews: currentShop.display_reviews,
          display_logo: currentShop.display_logo,
          logo_size: currentShop.logo_size,
          background_image: currentShop.background_image || "",
          use_background_image: currentShop.background_image ? true : false,
          use_welcome_page: currentShop.use_welcome_page,
          use_categories: currentShop.use_categories,
          meta_tag_content: currentShop.meta_tag_content,
          meta_tag_is_active: currentShop.meta_tag_is_active,
          meta_pixel_id: currentShop.meta_pixel_id,
          text_background_opacity: currentShop.text_background_opacity,
          text_color: currentShop.text_color,
          reorder_soldout_products: currentShop.reorder_soldout_products,
          bordered: currentShop.bordered,
          geo_blocked: currentShop.geo_blocked,
          restricted_countries: currentShop.restricted_countries,
          enter_button_text: currentShop.enter_button_text,
          elements: currentShop.elements,
        });
      }
      setLoading(false);
    });
  };

  const updateShopfronts = () => {
    // current only updating 1 shopfront
    const formData = new FormData();
    for (const attr in shopfront) {
      if (attr === "background_image") {
        if (!shopfront.use_background_image) {
          formData.append(attr, null);
        } else {
          formData.append(attr, shopfront[attr]?.file ? shopfront[attr].file : shopfront[attr]);
        }
      } else if (attr === "restricted_countries") {
        formData.append(attr, JSON.stringify(shopfront[attr]));
      } else {
        formData.append(attr, shopfront[attr]);
      }
    }

    return Auth.client.put(`${BACKEND_URL}/${API_VERSION}/shopfronts`, formData).then((res) => {
      const updatedShop = res.data[0];
      setShopfront({
        shopfront_id: updatedShop.shopfront_id,
        shopfront_type: updatedShop.shopfront_type,
        layout: updatedShop.layout,
        background_color: updatedShop.background_color || "#FFFBF6",
        accent_color: updatedShop.accent_color || "#8B5725",
        title: updatedShop.title,
        description: updatedShop.description,
        is_active: updatedShop.is_active,
        display_reviews: updatedShop.display_reviews,
        display_logo: updatedShop.display_logo,
        logo_size: updatedShop.logo_size,
        background_image: updatedShop.background_image || "",
        use_background_image: updatedShop.background_image ? true : false,
        use_welcome_page: updatedShop.use_welcome_page,
        use_categories: updatedShop.use_categories,
        meta_tag_content: updatedShop.meta_tag_content,
        meta_tag_is_active: updatedShop.meta_tag_is_active,
        meta_pixel_id: updatedShop.meta_pixel_id,
        text_background_opacity: updatedShop.text_background_opacity,
        text_color: updatedShop.text_color,
        reorder_soldout_products: updatedShop.reorder_soldout_products,
        bordered: updatedShop.bordered,
        geo_blocked: updatedShop.geo_blocked,
        restricted_countries: updatedShop.restricted_countries,
        enter_button_text: updatedShop.enter_button_text,
        elements: updatedShop.elements,
      });
    });
  };

  const refreshShopfront = () => {
    getShopfront();
  };

  useEffect(() => {
    getShopfront();
    // eslint-disable-next-line
  }, []);

  return (
    <ShopContext.Provider
      value={{ shopfront, setShopfront, shopfronts, setShopfronts, updateShopfronts, refreshShopfront, loading }}
    >
      {children}
    </ShopContext.Provider>
  );
};

const useShopContext = () => {
  return useContext(ShopContext);
};

export default useShopContext;
