import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Popper,
  TextField,
} from "@material-ui/core";
import { CheckBox, CheckBoxOutlineBlank, Delete } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: 500,
    [theme.breakpoints.down("sm")]: {
      minWidth: 200,
      maxWidth: 450,
    },
  },
  textField: {
    margin: theme.spacing(1, 0),
  },
  formControl: {
    margin: theme.spacing(1, 0),
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const CategoryDialog = ({
  categoryDialog,
  setCategoryDialog,
  selectedCategory,
  setSelectedCategory,
  updateCategory,
  setDeleteDialog,
  products,
}) => {
  const classes = useStyles();

  const smDown = useMediaQuery((theme) => theme.breakpoints.down("sm"), { noSsr: true });

  const handleClose = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setCategoryDialog(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedCategory({ ...selectedCategory, [name]: value });
  };

  const handleSave = () => {
    let updatedCategoryObj = {
      product_category_id: selectedCategory.product_category_id,
      product_category_name: selectedCategory.product_category_name,
      products: [],
    };
    if (selectedCategory.products.length > 0) {
      updatedCategoryObj["products"] = selectedCategory.products.map((product) => product.product_id);
    }

    updateCategory(updatedCategoryObj);
  };

  const CustomPopper = (props) => {
    return <Popper {...props} placement={smDown ? "top" : "bottom"} />;
  };

  return (
    <Dialog
      open={categoryDialog}
      onClose={handleClose}
      TransitionProps={{
        onExited: () => {
          setSelectedCategory({ product_category_name: "", product_category_id: "", products: [] });
        },
      }}
      PaperProps={{ classes: { root: classes.dialogPaper } }}
    >
      <DialogTitle>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          {`Update Category`}
          {
            <IconButton onClick={() => setDeleteDialog(true)}>
              <Delete color="error" />
            </IconButton>
          }
        </div>
      </DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          autoComplete="off"
          autoFocus
          fullWidth
          name="product_category_name"
          label="Category Title"
          placeholder="eg. New Arrival"
          classes={{ root: classes.textField }}
          margin="dense"
          onChange={handleInputChange}
          value={selectedCategory.product_category_name}
          required
        />

        <DialogContentText style={{ fontSize: "14px", marginTop: "16px" }}>
          Select products below to be under this category
        </DialogContentText>
        <Autocomplete
          multiple
          disableCloseOnSelect
          PopperComponent={CustomPopper}
          value={selectedCategory.products}
          onChange={(event, selectedProductsArr) => {
            setSelectedCategory({ ...selectedCategory, products: selectedProductsArr });
          }}
          options={products}
          getOptionLabel={(option) => option.title}
          getOptionSelected={(option, value) => option.product_id === value.product_id}
          renderOption={(option, { selected }) => {
            return (
              <Fragment>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selectedCategory.products.some((product) => product["product_id"] === option.product_id)}
                />
                {option.title}
              </Fragment>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Select Products"
              placeholder="Select or enter product name"
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                color="secondary"
                label={option.title}
                size="small"
                {...getTagProps({ index })}
              />
            ))
          }
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setCategoryDialog(false)}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={() => handleSave()}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CategoryDialog;
