import Auth from "../../../Auth";
import { BACKEND_URL, API_VERSION } from "../../../config";

export const updateOrderStatus = ({ id, status }) => {
  return Auth.client.patch(`${BACKEND_URL}/${API_VERSION}/vendor-orders/${id}?status=${status}`);
};

export const batchUpdateOrderStatus = ({ status, transactionIds }) => {
  return Auth.client.patch(`${BACKEND_URL}/${API_VERSION}/vendor-orders?status=${status}`, {
    transactions: transactionIds,
  });
};

export const sendDeliveryNotification = ({ transaction }) => {
  return Auth.client.post(`${BACKEND_URL}/${API_VERSION}/order-delivery-notification`, { transaction: transaction });
};

export const updateVendorOrderColDefs = (colDefs = []) => {
  const colDefStr = colDefs.join(",");
  return Auth.client.patch(`${BACKEND_URL}/${API_VERSION}/vendors`, { order_column_defs: colDefStr });
};
