import React, { Fragment, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { CheckBox, CheckBoxOutlineBlank, Delete } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { useSnackbar } from "notistack";

import { validatePromoCodeObj } from "../helpers/validation";
import useAuth from "../../../hooks/useAuth";
import { CURRENCY_PREFIX_MAP } from "../../../config/currency";

const useStyles = makeStyles((theme) => ({
  dialog: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "600px",
    },
  },
  textField: {
    margin: theme.spacing(1, 0),
  },
  formControl: {
    margin: theme.spacing(1, 0),
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  datePicker: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  inLineDiv: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: theme.spacing(3, 0),
    },
  },
  inLineRightComponent: {
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const PromoCodeDialog = ({
  openDialog,
  setOpenDialog,
  promoCode,
  setPromoCode,
  editMode,
  setEditMode,
  setDeleteDialog,
  setDeletePromoId,
  handleCreatePromoCode,
  handleEditPromoCode,
  products,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { user: vendor } = useAuth();

  const currencyPrefix = CURRENCY_PREFIX_MAP[vendor.currency];

  const [haveMinSpend, setHaveMinSpend] = useState(false);
  const [haveMaxRedemptions, setHaveMaxRedemptions] = useState(false);
  const [haveApplicableProducts, setHaveApplicableProducts] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPromoCode({ ...promoCode, [name]: value });
  };

  const handleSubmit = () => {
    const message = validatePromoCodeObj(promoCode, haveMinSpend, haveMaxRedemptions, haveApplicableProducts);
    if (message !== "") {
      enqueueSnackbar(message, { variant: "error" });
      return;
    }

    let obj = { ...promoCode };

    if (editMode) {
      if (!haveMinSpend) {
        obj["min_spend"] = null;
      }
      if (!haveMaxRedemptions) {
        obj["max_orders"] = null;
      }
      if (!haveApplicableProducts) {
        obj["products"] = [];
      } else {
        const applicableProductIds = obj["products"].map((product) => product.product_id);
        obj["products"] = applicableProductIds;
      }
      // console.log(obj);
      handleEditPromoCode(obj);
    } else {
      if (!haveMinSpend) {
        delete obj["min_spend"];
      }
      if (!haveMaxRedemptions) {
        delete obj["max_orders"];
      }
      if (!haveApplicableProducts) {
        obj["products"] = [];
      } else {
        const applicableProductIds = obj["products"].map((product) => product.product_id);
        obj["products"] = applicableProductIds;
      }
      handleCreatePromoCode(obj);
    }
  };

  useEffect(() => {
    if (promoCode) {
      if (promoCode.min_spend !== "") {
        setHaveMinSpend(true);
      }

      if (promoCode.max_orders !== "") {
        setHaveMaxRedemptions(true);
      }

      if (promoCode.products.length > 0) {
        setHaveApplicableProducts(true);
      }
    }
    // eslint-disable-next-line
  }, [promoCode]);

  return (
    <Fragment>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        TransitionProps={{
          onExited: () => {
            setPromoCode({
              promo_code: "",
              discount_value: "",
              discount_type: "VALUE",
              start_date: new Date(),
              num_days: 1,
              min_spend: "",
              max_orders: "",
              first_time_customer: false,
              products: [],
            });
            setEditMode(false);
            setHaveMinSpend(false);
            setHaveMaxRedemptions(false);
            setHaveApplicableProducts(false);
          },
        }}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            {editMode ? "Update Promo Code" : "New Promo Code"}
            {editMode && (
              <IconButton
                onClick={() => {
                  setDeletePromoId(promoCode.id);
                  setDeleteDialog(true);
                }}
              >
                <Delete color="error" />
              </IconButton>
            )}
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ fontSize: "14px" }}>* indicates field cannot be empty</DialogContentText>
          <TextField
            variant="outlined"
            autoComplete="off"
            autoFocus
            fullWidth
            name="promo_code"
            label="Promo Code"
            placeholder="eg. PROMO1"
            classes={{ root: classes.textField }}
            margin="dense"
            onChange={handleInputChange}
            value={promoCode.promo_code}
            required
          />
          <div className={classes.inLineDiv}>
            <DatePicker
              autoOk
              disableToolbar
              variant="inline"
              label="Start Date"
              format="dd-MM-yyyy"
              inputVariant="outlined"
              margin="dense"
              className={classes.datePicker}
              minDate={new Date()}
              error={false}
              helperText={null}
              onChange={(date) => setPromoCode({ ...promoCode, start_date: date })}
              value={promoCode.start_date}
              required
            />
            <TextField
              variant="outlined"
              label="Promo Period"
              className={classes.inLineRightComponent}
              type="number"
              inputProps={{
                min: 1,
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">day(s)</InputAdornment>,
              }}
              name="num_days"
              margin="dense"
              onChange={handleInputChange}
              value={promoCode.num_days}
              required
              onWheel={(e) => e.target.blur()}
            />
          </div>
          <div className={classes.inLineDiv}>
            <FormControl variant="outlined" className={classes.formControl} margin="dense" required>
              <InputLabel>Discount Type</InputLabel>
              <Select
                label="Discount Type"
                name="discount_type"
                value={promoCode.discount_type}
                onChange={handleInputChange}
              >
                <MenuItem value="VALUE">Fix Amount</MenuItem>
                <MenuItem value="PERCENTAGE">By Percentage</MenuItem>
              </Select>
            </FormControl>
            <TextField
              variant="outlined"
              label="Discount Amount"
              className={classes.inLineRightComponent}
              type="number"
              inputProps={{
                min: 0,
                step: 0.01,
              }}
              InputProps={{
                startAdornment: promoCode.discount_type === "VALUE" && (
                  <InputAdornment position="start">{currencyPrefix}</InputAdornment>
                ),
                endAdornment: promoCode.discount_type === "PERCENTAGE" && (
                  <InputAdornment position="end">%</InputAdornment>
                ),
              }}
              name="discount_value"
              margin="dense"
              onChange={handleInputChange}
              value={promoCode.discount_value}
              required
              onWheel={(e) => e.target.blur()}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", margin: "16px 0px" }}>
            <FormControlLabel
              control={<Checkbox checked={haveMinSpend} onChange={() => setHaveMinSpend(!haveMinSpend)} />}
              label="Set Minimum Spending"
            />
            {haveMinSpend && (
              <TextField
                autoFocus
                variant="outlined"
                label="Min. Spending"
                margin="dense"
                type="number"
                inputProps={{
                  min: 0,
                  step: 0.01,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{currencyPrefix}</InputAdornment>,
                }}
                name="min_spend"
                onChange={handleInputChange}
                value={promoCode.min_spend}
                onWheel={(e) => e.target.blur()}
              />
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "column", margin: "16px 0px" }}>
            <FormControlLabel
              control={
                <Checkbox checked={haveMaxRedemptions} onChange={() => setHaveMaxRedemptions(!haveMaxRedemptions)} />
              }
              label="Set Maximum Number of Redemptions"
            />
            {haveMaxRedemptions && (
              <TextField
                autoFocus
                variant="outlined"
                label="Max. Redemptions"
                margin="dense"
                type="number"
                inputProps={{
                  min: 1,
                }}
                name="max_orders"
                onChange={handleInputChange}
                value={promoCode.max_orders}
                onWheel={(e) => e.target.blur()}
              />
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "column", margin: "16px 0px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={haveApplicableProducts}
                  onChange={() => setHaveApplicableProducts(!haveApplicableProducts)}
                />
              }
              label="Set Applicable Product(s)"
            />
            {haveApplicableProducts && (
              <Fragment>
                <DialogContentText style={{ fontSize: "14px", fontStyle: "italic" }}>
                  Note: Orders containing other products that are not listed below will not be applicable for this promo
                  code.
                </DialogContentText>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  size="small"
                  value={promoCode.products}
                  onChange={(event, selectedProductsArr) => {
                    setPromoCode({ ...promoCode, products: selectedProductsArr });
                  }}
                  options={products}
                  getOptionLabel={(option) => option.title}
                  getOptionSelected={(option, value) =>
                    option.title === value.title && option.product_id === value.product_id
                  }
                  renderOption={(option, { selected }) => {
                    return (
                      <Fragment>
                        <Checkbox
                          icon={<CheckBoxOutlineBlank fontSize="small" />}
                          checkedIcon={<CheckBox fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={promoCode.products.some(
                            (product) =>
                              product["title"] === option.title && product["product_id"] === option.product_id
                          )}
                        />
                        {option.title}
                      </Fragment>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select Products"
                      placeholder="Select or enter product name"
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        color="secondary"
                        label={option.title}
                        size="small"
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                />
              </Fragment>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "column", margin: "16px 0px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={promoCode.first_time_customer}
                  onChange={() => setPromoCode({ ...promoCode, first_time_customer: !promoCode.first_time_customer })}
                />
              }
              label="One Redemption Per Customer"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setOpenDialog(false)}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={() => handleSubmit()}>
            {editMode ? "Save" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default PromoCodeDialog;
