import React, { useState, useEffect } from "react";
import { makeStyles, Typography, Button } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSnackbar } from "notistack";

import { reorder } from "../../../utils";
import FaqDialog from "../components/FaqDialog";
import FaqCard from "../components/FaqCard";
import GenericContainer from "../../../components/containers/GenericContainer";

import { deleteVendorFaq, fetchVendorFaq, reorderVendorFaq } from "../api";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 400,
    margin: theme.spacing(1, 0),
  },
  headerDescription: {
    marginBottom: "12px",
  },
  faqContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    margin: `${theme.spacing(1)}px 0`,
    borderRadius: 4,
  },
  gridItem: {
    padding: theme.spacing(1.5, 0),
  },
  leftMargin: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(4.5),
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "10px 0 25px",
  },
}));

const FaqSettings = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [faqDialog, setFaqDialog] = useState(false);
  const [faqList, setFaqList] = useState([]);

  const [faq, setFaq] = useState({
    question: "",
    answer: "",
  });

  const selectFaqForEdit = (faq) => {
    setFaq(faq);
    setFaqDialog(true);
  };

  const deleteFaq = (faqId) => {
    deleteVendorFaq(faqId)
      .then((res) => {
        setFaqList(res.data);
        enqueueSnackbar("FAQ deleted.", { variant: "success" });
      })
      .catch(() =>
        enqueueSnackbar("Something went wrong, feel free to reach out to us on Instagram", { variant: "error" })
      );
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    let reorderedFaqs = reorder(faqList, result.source.index, result.destination.index);
    setFaqList(reorderedFaqs);
    reorderVendorFaq(reorderedFaqs).catch(() =>
      enqueueSnackbar("Something went wrong, feel free to reach out to us on Instagram", { variant: "error" })
    );
  };

  const getListStyle = (isDraggingOver) => ({
    padding: 8,
    background: isDraggingOver ? "#FFF2D2" : "#ebebeb",
  });

  useEffect(() => {
    fetchVendorFaq()
      .then((res) => {
        setFaqList(res.data);
      })
      .catch((err) =>
        enqueueSnackbar("Something went wrong, feel free to reach out to us on Instagram", { variant: "error" })
      );
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <GenericContainer>
        <Typography variant="h5" className={classes.header}>
          FAQ
        </Typography>
        <Typography variant="body1" color="textSecondary" className={classes.headerDescription}>
          Answer your customers' commonly asked questions.
        </Typography>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                className={classes.faqContainer}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                {faqList &&
                  faqList.map((faq, index) => (
                    <Draggable key={faq.faq_id} draggableId={faq.faq_id} index={index}>
                      {(provided, snapshot) => (
                        <FaqCard
                          faq={faq}
                          provided={provided}
                          selectFaqForEdit={selectFaqForEdit}
                          deleteFaq={deleteFaq}
                        />
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Button variant="contained" color="primary" fullWidth onClick={() => setFaqDialog(true)}>
          Add FAQ
        </Button>
      </GenericContainer>
      <FaqDialog open={faqDialog} setOpen={setFaqDialog} setFaqList={setFaqList} faq={faq} setFaq={setFaq} />
    </>
  );
};

export default FaqSettings;
