import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import {
  AccountBalanceTwoTone,
  AccountBalanceWalletTwoTone,
  CreditCardTwoTone,
  Delete,
  LocalAtmTwoTone,
} from "@material-ui/icons";
import { Alert, Autocomplete } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import { DropzoneAreaBase } from "material-ui-dropzone";

import PaymentInstructions from "../components/PaymentInstructions";
import StripeConnectContainer from "../components/StripeConnectContainer";
import GenericContainer from "../../../components/containers/GenericContainer";

import useAuth from "../../../hooks/useAuth";
import { ACCEPTED_CURRENCIES } from "../../../config/currency";
import { getVendorStripeAccount, updatePaymentMethods, uploadCustomPaynowQr } from "../api";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 400,
    margin: theme.spacing(1, 0),
  },
  headerDescription: {
    marginBottom: "12px",
  },
  section: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  saveButton: {
    width: 150,
  },
  gridItem: {
    padding: theme.spacing(1.5, 0),
  },
  settingsIcon: {
    marginRight: theme.spacing(1.5),
  },
  paperSelectContent: {
    marginLeft: "36px",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    margin: theme.spacing(1),
  },
  select: {
    width: "40%",
    marginRight: theme.spacing(1),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  autocomplete: {
    marginBottom: theme.spacing(1.5),
    width: "100%",
  },
  dropzoneContainer: {
    margin: theme.spacing(1, 0),
    width: "100%",
    minHeight: 70,
    height: 110,
  },
  dropzoneParagraph: {
    fontSize: 14,
  },
  imageItem: {
    userSelect: "none",
    margin: theme.spacing(1),
    height: "120px",
    position: "relative",
    width: "fit-content",
  },
  image: {
    objectFit: "contain",
    height: "120px",
  },
  deleteBtn: {
    position: "absolute",
    top: -12,
    right: -12,
  },
}));

const PaymentSettings = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { user, refresh } = useAuth();

  const [currency, setCurrency] = useState("sgd");
  const [paymentMethods, setPaymentMethods] = useState({});
  const [customPaynowImage, setCustomPaynowImage] = useState();
  const [vendorStripeAccount, setVendorStripeAccount] = useState({});
  const [stripeWarning, setStripeWarning] = useState(false);

  const [paynowWarning, setPaynowWarning] = useState(false);

  const handlePaymentMethodFieldChange = (e, paymentMethod) => {
    const { name, value } = e.target;

    const paymentObj = paymentMethods[paymentMethod];
    paymentObj[name] = value;

    setPaymentMethods({ ...paymentMethods, [paymentMethod]: paymentObj });
  };

  const handleStripeBooleanChange = (e) => {
    const { name, checked } = e.target;

    const paymentObj = paymentMethods.ST;
    paymentObj[name] = checked;

    setPaymentMethods({ ...paymentMethods, ST: paymentObj });
  };

  const payNowLabel = (payNowMethod) => {
    switch (payNowMethod) {
      case "paynow_mobile":
        return "PayNow Mobile";
      case "uen":
        return "UEN";
      case "vpa":
        return "VPA";
      default:
        return "PayNow Mobile";
    }
  };

  const handlePaymentObjSwitch = (e) => {
    if (paymentMethods[e.target.name]) {
      let paymentObj = { ...paymentMethods[e.target.name] };
      paymentObj.is_enabled = e.target.checked;
      setPaymentMethods({ ...paymentMethods, [e.target.name]: paymentObj });
    } else {
      const newPaymentMethod = { payment_method: e.target.name, is_enabled: true };
      setPaymentMethods({ ...paymentMethods, [e.target.name]: newPaymentMethod });
    }
  };

  const handleUpdateDetails = () => {
    const pnObj = paymentMethods.PN;
    if (
      pnObj?.is_enabled &&
      !pnObj?.uen &&
      (!pnObj?.paynow_mobile || pnObj?.paynow_mobile === "+65") &&
      !customPaynowImage
    ) {
      enqueueSnackbar("Enter your PayNow details", { variant: "error" });
      return;
    }

    if (
      paymentMethods.ST?.is_enabled &&
      !paymentMethods.ST.is_stripe_paynow_enabled &&
      !paymentMethods.ST.is_stripe_credit_card_enabled
    ) {
      enqueueSnackbar("You need to enable at least 1 Stripe payment type", { variant: "error" });
      return;
    }

    const paymentSettingsObj = {
      payment_methods: Object.values(paymentMethods),
      currency: currency,
    };

    updatePaymentMethods(paymentSettingsObj)
      .catch((err) => {
        // console.log(err);
        enqueueSnackbar("Failed to update payment details!", { variant: "error" });
      })
      .then((res) => {
        // if qr code is a url string, image is already upload, skip upload
        if (typeof customPaynowImage !== "string") {
          return uploadCustomPaynowQr(customPaynowImage);
        }
        return res;
      })
      .catch((err) => {
        // console.log(err);
        enqueueSnackbar("Failed to upload custom PayNow QR!", { variant: "error" });
      })
      .then((res) => {
        // console.log(res);
        enqueueSnackbar("Payment details updated successfully!", { variant: "success" });
      })
      .finally(() => refresh());
  };

  const handleCurrencyChange = (e, value) => {
    setCurrency(value);
    if (value !== "sgd") {
      // render warning
      setPaynowWarning(true);

      // disable Paynow
      let paymentObj = { ...paymentMethods.PN };
      paymentObj.is_enabled = false;
      setPaymentMethods({ ...paymentMethods, PN: paymentObj });
    } else {
      setPaynowWarning(false);
    }
  };

  useEffect(() => {
    if (user && user.payment_methods) {
      const paymentMethodObj = user.payment_methods.reduce(
        (obj, curr) => ({ ...obj, [curr.payment_method]: curr }),
        {},
      );
      setPaymentMethods(paymentMethodObj);
      setCustomPaynowImage(paymentMethodObj?.PN?.custom_qr_code);
      setCurrency(user.currency);
      setPaynowWarning(user.currency !== "sgd");
    }

    getVendorStripeAccount()
      .then((res) => {
        setVendorStripeAccount(res.data);
        setStripeWarning(res.status !== 200);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, [user]);

  return (
    <GenericContainer>
      <Typography variant="h5" className={classes.header}>
        Payments
      </Typography>
      <Typography variant="body1" color="textSecondary" className={classes.headerDescription}>
        Select your payment collection methods
      </Typography>
      <Autocomplete
        id="currency-autocomplete"
        options={ACCEPTED_CURRENCIES}
        getOptionLabel={(option) => option.toUpperCase()}
        className={classes.autocomplete}
        value={currency}
        onChange={handleCurrencyChange}
        renderInput={(params) => (
          <TextField {...params} label="Select Currency" variant="outlined" margin="dense" />
        )}
      />
      <Grid container>
        <Grid item xs={12} className={classes.gridItem}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <AccountBalanceWalletTwoTone className={classes.settingsIcon} />
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              <b>PayNow</b>
            </Typography>
            <Switch
              color="primary"
              name="PN"
              checked={paymentMethods.PN ? paymentMethods.PN.is_enabled : false}
              onChange={handlePaymentObjSwitch}
              disabled={currency !== "sgd"}
            />
          </div>
          {paynowWarning && (
            <Alert severity="error">PayNow is only available in SGD within Singapore.</Alert>
          )}
          {paymentMethods.PN && paymentMethods.PN.is_enabled && (
            <Fragment>
              <div className={classes.paperSelectContent}>
                <Typography variant="body1">
                  Receive payments directly into your PayNow account.{" "}
                  <b>Requires manual payment verification.</b>
                </Typography>
                <div className={classes.section} style={{ marginTop: 8 }}>
                  <FormControl variant="outlined" margin="dense" className={classes.select}>
                    <InputLabel id="outlined-select">Type</InputLabel>
                    <Select
                      labelId="outlined-select"
                      id="select-outlined"
                      label="Type"
                      name="paynow_method"
                      value={paymentMethods.PN?.paynow_method || "paynow_mobile"}
                      onChange={(e) => handlePaymentMethodFieldChange(e, "PN")}
                    >
                      <MenuItem value="paynow_mobile">Mobile</MenuItem>
                      <MenuItem value="uen">UEN</MenuItem>
                      <MenuItem value="vpa">VPA</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    autoComplete="off"
                    fullWidth
                    type="tel"
                    name={paymentMethods.PN?.paynow_method}
                    label={payNowLabel(paymentMethods.PN?.paynow_method)}
                    value={paymentMethods.PN[paymentMethods.PN?.paynow_method] || ""}
                    onChange={(e) => handlePaymentMethodFieldChange(e, "PN")}
                  />
                </div>
                <div className={classes.section}>
                  {customPaynowImage ? (
                    <div className={classes.imageItem}>
                      <img
                        className={classes.image}
                        src={customPaynowImage.data || customPaynowImage}
                        alt="product_image"
                      />
                      <IconButton
                        disableRipple
                        size="small"
                        className={classes.deleteBtn}
                        onClick={() => setCustomPaynowImage()}
                      >
                        <Delete />
                      </IconButton>
                    </div>
                  ) : (
                    <DropzoneAreaBase
                      dropzoneText={
                        <>
                          Upload custom PayNow QR (max. 500kb) <br />
                          <Button variant="contained" color="primary" style={{ marginTop: 8 }}>
                            Upload
                          </Button>
                        </>
                      }
                      dropzoneParagraphClass={classes.dropzoneParagraph}
                      dropzoneClass={classes.dropzoneContainer}
                      acceptedFiles={["image/*"]}
                      filesLimit={1}
                      onAdd={(images) => setCustomPaynowImage(images?.[0])}
                      // showPreviewsInDropzone={false}
                      maxFileSize={500000} // allow up to 10MB
                    />
                  )}
                </div>
              </div>
              <PaymentInstructions
                paymentMethods={paymentMethods}
                setPaymentMethods={setPaymentMethods}
                paymentMethodIdentifier="PN"
              />
            </Fragment>
          )}
        </Grid>

        <Grid item xs={12} className={classes.gridItem}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <CreditCardTwoTone className={classes.settingsIcon} />
            <Typography variant="h6" style={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
              <b>Stripe (Credit Card, Auto PayNow)</b>
            </Typography>
            <Switch
              color="primary"
              name="ST"
              checked={paymentMethods.ST ? paymentMethods.ST.is_enabled : false}
              onChange={handlePaymentObjSwitch}
            />
          </div>

          <Typography variant="body2" style={{ margin: "8px 0 12px 36px", lineHeight: 1.5 }}>
            Powered by <b>Stripe Connect</b>. Link your Stripe account to enable credit card/PayNow
            payments.{" "}
            <a href="https://stripe.com/en-sg/pricing" target="_blank" rel="noreferrer">
              Additional fees apply.
            </a>
          </Typography>
          {paymentMethods.ST && paymentMethods.ST.is_enabled && (
            <Fragment>
              <StripeConnectContainer
                vendorStripeAccount={vendorStripeAccount}
                paymentMethods={paymentMethods}
                handleStripeBooleanChange={handleStripeBooleanChange}
                needsMigration={stripeWarning}
              />
              <PaymentInstructions
                paymentMethods={paymentMethods}
                setPaymentMethods={setPaymentMethods}
                paymentMethodIdentifier="ST"
              />
            </Fragment>
          )}
        </Grid>

        <Grid item xs={12} className={classes.gridItem}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <LocalAtmTwoTone className={classes.settingsIcon} />
            <Typography variant="h6" style={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
              <b>Cash On Delivery</b>
            </Typography>
            <Switch
              color="primary"
              name="CA"
              checked={paymentMethods.CA ? paymentMethods.CA.is_enabled : false}
              onChange={handlePaymentObjSwitch}
            />
          </div>
          {paymentMethods.CA && paymentMethods.CA.is_enabled && (
            <PaymentInstructions
              paymentMethods={paymentMethods}
              setPaymentMethods={setPaymentMethods}
              paymentMethodIdentifier="CA"
            />
          )}
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <AccountBalanceTwoTone className={classes.settingsIcon} />
            <Typography variant="h6" style={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
              <b>Bank Transfer</b>
            </Typography>
            <Switch
              color="primary"
              name="BT"
              checked={paymentMethods.BT?.is_enabled || false}
              onChange={handlePaymentObjSwitch}
            />
          </div>
          {paymentMethods.BT?.is_enabled && (
            <>
              <div style={{ margin: "12px 8px 8px 36px" }}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  autoComplete="off"
                  fullWidth
                  name="bank_name"
                  label="Bank Name"
                  value={paymentMethods.BT?.bank_name || ""}
                  onChange={(e) => handlePaymentMethodFieldChange(e, "BT")}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  autoComplete="off"
                  fullWidth
                  name="bank_number"
                  label="Bank Number"
                  value={paymentMethods.BT?.bank_number || ""}
                  onChange={(e) => handlePaymentMethodFieldChange(e, "BT")}
                />
              </div>
              <PaymentInstructions
                paymentMethods={paymentMethods}
                setPaymentMethods={setPaymentMethods}
                paymentMethodIdentifier="BT"
              />
            </>
          )}
        </Grid>
      </Grid>

      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          className={classes.saveButton}
          onClick={() => handleUpdateDetails()}
        >
          Save
        </Button>
      </div>
    </GenericContainer>
  );
};

export default PaymentSettings;
