import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Typography, Button } from "@material-ui/core";

import AnimatedLogo from "../components/AnimatedLogo";
import { BACKEND_URL } from "../config";
import StyledTypography from "../components/StyledTypography";

const styles = (theme) => ({
  root: {
    height: "100vh",
    backgroundColor: theme.palette.background.dark,
    display: "grid",
    placeItems: "center",
  },
  paperRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    gap: theme.spacing(1),
    margin: theme.spacing(1),
    padding: theme.spacing(4, 2),
    minHeight: "60vh",
    width: "40vw",
    [theme.breakpoints.down("sm")]: {
      width: "85vw",
    },
  },
});

const VerifiedEmail = (props) => {
  const { classes } = props;
  const { id } = useParams();

  const [loading, setLoading] = useState(true);

  const verifyAccount = () => {
    axios
      .post(`${BACKEND_URL}/v1/verify/${id}`)
      .then((res) => {
        // console.log(res);
        setTimeout(() => setLoading(false), 1200);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    verifyAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div className={classes.root}>
        <Paper elevation={3} className={classes.paperRoot} style={{ minHeight: "60vh" }}>
          <AnimatedLogo style={{ maxWidth: 80 }} />
        </Paper>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Paper elevation={3} className={classes.paperRoot}>
        <img src="/email-v2.png" style={{ display: "block", maxWidth: 320 }} alt="email activation" />
        <Typography variant="h1">
          <StyledTypography>Welcome to Carte!</StyledTypography>
        </Typography>
        <Typography variant="body1">Thanks for verifying your email</Typography>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/login"
          style={{ minWidth: 120, marginTop: 32 }}
        >
          Login
        </Button>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(VerifiedEmail);
