import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Divider,
  Button,
  IconButton,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { Close, FilterNoneOutlined } from "@material-ui/icons";
import CopyToClipboard from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";

import DeleteOrderDialog from "./DeleteOrderDialog";
import CartItem from "./CartItem";
import useAuth from "../../../hooks/useAuth";
import useOrdersContext from "../../../hooks/useOrdersContext";

import { parseDate } from "../../../utils";
import Auth from "../../../Auth";
import { BACKEND_URL, API_VERSION } from "../../../config";
import { paymentMethodMap } from "../helpers/utils";
import { CURRENCY_PREFIX_MAP } from "../../../config/currency";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  dialogHeader: {
    margin: theme.spacing(1, 0),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1, 3),
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  indent: {
    paddingLeft: theme.spacing(2),
  },
  deleteButton: {
    margin: theme.spacing(0, 1),
    color: theme.palette.common.white,
    background: theme.palette.error.main,
    "&:hover": {
      background: theme.palette.error.dark,
    },
  },
}));

const OrderDialog = ({ open, setOpen, order, ...props }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { user: vendor } = useAuth();
  const { refreshOrders } = useOrdersContext();

  const [deleteOrderDialogOpen, setDeleteOrderDialogOpen] = useState(false);

  if (!order) {
    return <Fragment />;
  }

  const handleDelete = () => {
    Auth.client
      .delete(`${BACKEND_URL}/${API_VERSION}/vendor-orders/${order.transaction_id}`)
      .then((res) => {
        enqueueSnackbar("Order deleted!", { variant: "success" });
        setOpen(false);
        refreshOrders();
      })
      .catch((err) => {
        enqueueSnackbar(`Oops, something went wrong (${err.response.status})`, { variant: "error" });
      });
  };

  return (
    <Fragment>
      <Dialog open={open} onClose={() => setOpen(false)} {...props} classes={{ paper: classes.paper }}>
        <div className={classes.dialogHeader}>
          <Typography variant="h4" style={{ fontWeight: 500 }}>
            Order Details (ID: {order.public_transaction_id})
          </Typography>
          <IconButton size="small" onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </div>
        <DialogContent className={classes.dialogContent}>
          <Typography variant="h5" style={{ marginBottom: 8 }}>
            Customer
          </Typography>
          <Typography variant="body2">
            <b>Name:</b> {order.customer.customer_name}
          </Typography>
          <Typography variant="body2">
            <b>Email:</b> {order.customer.customer_email}
          </Typography>
          <Typography variant="body2">
            <b>Contact Number:</b> {order.customer.contact_number}
          </Typography>
          {order.customer.telegram_handle && (
            <Typography variant="body2">
              <b>Telegram:</b> {order.customer.telegram_handle}
            </Typography>
          )}
          {order.customer.instagram_handle && (
            <Typography variant="body2">
              <b>Instagram:</b> {order.customer.instagram_handle}
            </Typography>
          )}

          <Divider className={classes.divider} />
          <Typography variant="h5" style={{ marginBottom: 8 }}>
            Payment
          </Typography>
          <Typography variant="body2">
            <b>Total Amount:</b> {CURRENCY_PREFIX_MAP[order.currency]}
            {order.total_price}
          </Typography>
          <Typography variant="body2">
            <b>Payment Method:</b> {paymentMethodMap[order.payment_method]}
          </Typography>
          <Typography variant="body2">
            <b>Promo Code: </b> {order.promo_code ? order.promo_code : "-"}
          </Typography>

          <Divider className={classes.divider} />
          <Typography variant="h5" style={{ marginBottom: 8 }}>
            {order.delivery_address ? "Delivery" : "Self Collection"}
          </Typography>
          {order.delivery_recipient_name && (
            <Typography variant="body2">
              <b>Recipient's Name:</b> {order.delivery_recipient_name}
            </Typography>
          )}
          {order.delivery_address && (
            <Typography variant="body2">
              <b>Delivery Address:</b> {order.delivery_address}
            </Typography>
          )}
          {order.delivery_date && (
            <Typography variant="body2">
              <b>Date:</b> {parseDate(order.delivery_date)}
            </Typography>
          )}
          {order.delivery_option && (
            <Typography variant="body2">
              <b>Delivery Option:</b> {order.delivery_option.name}
            </Typography>
          )}
          {order.delivery_slot && (
            <Typography variant="body2">
              <b>Delivery Slot:</b> {parseDate(order.delivery_slot.date)}
            </Typography>
          )}
          {order.delivery_option && (
            <Typography variant="body2">
              <b>Delivery Fee:</b> {CURRENCY_PREFIX_MAP[order.currency]}
              {order.delivery_fee}
            </Typography>
          )}
          {order.collection_date && (
            <Typography variant="body2">
              <b>Date:</b> {parseDate(order.collection_date)}
            </Typography>
          )}
          {order.collection_option && (
            <Typography variant="body2">
              <b>Collection Address:</b> {order.collection_option.address}
            </Typography>
          )}
          {order.collection_option && (
            <Typography variant="body2">
              <b>Collection Option:</b> {order.collection_option.name}
            </Typography>
          )}

          {order.special_request && (
            <Fragment>
              <Divider className={classes.divider} />
              <Typography variant="h5" style={{ marginBottom: 8 }}>
                Special Requests
              </Typography>
              <Typography variant="body2">{order.special_request}</Typography>
            </Fragment>
          )}

          <Divider className={classes.divider} />
          <Typography variant="h5" style={{ marginBottom: 8 }}>
            Cart
          </Typography>
          {order.cart_items && order.cart_items.map((item, i) => <CartItem key={i} item={item} classes={classes} />)}

          <Divider className={classes.divider} />
          <Typography variant="h5" style={{ marginBottom: 8 }}>
            Review Link
          </Typography>
          <TextField
            variant="outlined"
            margin="dense"
            value={`https://${vendor.subdomain}.carteapp.io/review/${order.transaction_id}`}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CopyToClipboard
                    text={`https://${vendor.subdomain}.carteapp.io/review/${order.transaction_id}`}
                    onCopy={() => enqueueSnackbar("Link copied", { variant: "success" })}
                  >
                    <IconButton size="small">
                      <FilterNoneOutlined fontSize="small" />
                    </IconButton>
                  </CopyToClipboard>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteOrderDialogOpen(true)} className={classes.deleteButton} variant="contained">
            Delete Order
          </Button>
        </DialogActions>
      </Dialog>
      <DeleteOrderDialog open={deleteOrderDialogOpen} setOpen={setDeleteOrderDialogOpen} handleDelete={handleDelete} />
    </Fragment>
  );
};

export default OrderDialog;
