export const validatePromoCodeObj = (promoCode, minSpend, maxRedemptions, applicableProducts) => {
  if (promoCode.promo_code === "") {
    return "Promo Code cannot be empty!";
  }

  if (promoCode.num_days === "") {
    return "Promo Period cannot be empty!";
  }

  if (promoCode.num_days < 1) {
    return "Promo Period cannot be less than a day!";
  }

  if (promoCode.discount_value === "") {
    return "Discount value cannot be empty!";
  }

  if (promoCode.discount_value <= 0) {
    return "Discount value cannot be less than or equals to 0!";
  }

  if (minSpend && promoCode.min_spend === "") {
    return "Minimum spending cannot be empty!";
  }

  if (minSpend && promoCode.min_spend < 0) {
    return "Minimum spending cannot be less than $0!";
  }

  if (maxRedemptions && promoCode.max_orders === "") {
    return "Max number of redemptions cannot be empty!";
  }

  if (maxRedemptions && promoCode.max_orders < 1) {
    return "Max number of redemptions cannot be less than 1!";
  }

  if (applicableProducts && promoCode.products.length < 1) {
    return "Need to set at least 1 product!";
  }

  return "";
};
