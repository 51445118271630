import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";

import { createCollectionOption } from "../api";

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    width: "400px",
  },
  textField: {
    margin: theme.spacing(1, 0),
  },
}));

const CollectionOptionDialog = ({ setVendor, open, setOpen }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [collectionOption, setCollectionOption] = useState({
    name: "",
    address: "",
    instructions: "",
    enable_collection_slots: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCollectionOption({ ...collectionOption, [name]: value });
  };

  const handleCreateCollectionOption = () => {
    if (collectionOption.name === "") {
      enqueueSnackbar("Collection option name cannot be empty", { variant: "error" });
      return;
    }

    if (collectionOption.address === "") {
      enqueueSnackbar("Collection address cannot be empty", { variant: "error" });
      return;
    }

    createCollectionOption(collectionOption)
      .then((res) => {
        setVendor(res.data);
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Problem encountered when creating collection option", { variant: "error" });
      });
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogRoot }}
      maxWidth="md"
      open={open}
      onClose={() => setOpen(false)}
      TransitionProps={{
        onExit: () => {
          setCollectionOption({ name: "", address: "", instructions: "", enable_collection_slots: false });
        },
      }}
    >
      <DialogTitle>New Collection Option</DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          autoComplete="off"
          fullWidth
          name="name"
          label="Collection Option Name"
          classes={{ root: classes.textField }}
          value={collectionOption.name}
          onChange={(e) => handleInputChange(e)}
        />
        <TextField
          variant="outlined"
          autoComplete="off"
          fullWidth
          name="address"
          label="Collection Address"
          classes={{ root: classes.textField }}
          value={collectionOption.address}
          onChange={(e) => handleInputChange(e)}
        />
        <TextField
          variant="outlined"
          autoComplete="off"
          fullWidth
          multiline
          rows={4}
          name="instructions"
          label="Collection Instructions (Optional)"
          classes={{ root: classes.textField }}
          value={collectionOption.instructions}
          onChange={(e) => handleInputChange(e)}
        />
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={collectionOption.enable_collection_slots}
              onChange={(e) => setCollectionOption({ ...collectionOption, enable_collection_slots: e.target.checked })}
            />
          }
          label={<Typography variant="body2">Allow customers to select a date for self collection</Typography>}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleCreateCollectionOption}>
          Add Collection Option
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CollectionOptionDialog;
