import Auth from "../../../Auth";
import { BACKEND_URL, API_VERSION } from "../../../config";

export const createProduct = (product) => {
  return Auth.client.post(`${BACKEND_URL}/${API_VERSION}/products`, product);
};

export const addImagesToProduct = (productId, formData) => {
  return Auth.client.post(`${BACKEND_URL}/${API_VERSION}/products/${productId}/images`, formData);
};

export const getProduct = (id) => {
  return Auth.client.get(`${BACKEND_URL}/${API_VERSION}/products/${id}`);
};

export const updateProduct = (product, id) => {
  return Auth.client.put(`${BACKEND_URL}/${API_VERSION}/products/${id}`, product);
};

export const updateProductVariant = (productId, product, vendor) => {
  const variants = product.variants;
  const requests = variants.map((v, index) => {
    const formData = new FormData();

    for (const attr in v) {
      if (attr === "product_image") {
        formData.append(attr, v[attr] ? v[attr].file : null);
      } else if (attr === "product_image_caption") {
        if (vendor.permissions && vendor.permissions.includes("premium")) {
          formData.append(attr, v[attr] ? v[attr] : "");
        }
      } else {
        formData.append(attr, v[attr]);
      }
    }

    formData.append(
      "available_start_time",
      product.available_start_time !== null ? product.available_start_time.toISOString() : null
    );
    formData.append(
      "available_end_time",
      product.available_end_time !== null ? product.available_end_time.toISOString() : null
    );

    return Auth.client.put(
      `${BACKEND_URL}/${API_VERSION}/products/${productId}/product-variants?index=${index}`,
      formData
    );
  });

  return Promise.all(requests);
};

export const deleteProduct = (id) => {
  return Auth.client.delete(`${BACKEND_URL}/${API_VERSION}/products/${id}`);
};

export const duplicateProduct = (productId) => {
  return Auth.client.post(`${BACKEND_URL}/${API_VERSION}/products/${productId}`);
};

export const updateImage = (product, image) => {
  return Auth.client.patch(
    `${BACKEND_URL}/${API_VERSION}/products/${product.product_id}/images/${image.image_id}`,
    image
  );
};
