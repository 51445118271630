import React from "react";
import { TextField } from "@material-ui/core";

const PaymentInstructions = ({ paymentMethods, setPaymentMethods, paymentMethodIdentifier }) => {
  return (
    <div style={{ margin: "0 8px 12px 36px" }}>
      <TextField
        margin="dense"
        variant="outlined"
        fullWidth
        autoComplete="off"
        type="text"
        name={paymentMethodIdentifier}
        label="Payment Instructions"
        helperText="Leave blank if not necessary"
        multiline
        rows={2}
        value={
          paymentMethods[paymentMethodIdentifier] && paymentMethods[paymentMethodIdentifier].payment_instructions
            ? paymentMethods[paymentMethodIdentifier].payment_instructions
            : ""
        }
        onChange={(event) => {
          let paymentObj = paymentMethods[paymentMethodIdentifier];
          paymentObj.payment_instructions = event.target.value;
          setPaymentMethods({ ...paymentMethods, [paymentMethodIdentifier]: paymentObj });
        }}
      />
    </div>
  );
};

export default PaymentInstructions;
