import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Card, CardContent, CardMedia, Chip, Collapse, Paper, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";

import placeholder from "../../../assets/placeholder-image.png";

import { getLowestPrice, getPercentageDiscount, variantOnDiscount } from "../helpers/utils";
import useAuth from "../../../hooks/useAuth";
import { prefixCurrency } from "../../../config/currency";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 400,
    margin: theme.spacing(1, 0, 2),
  },
  paper: {
    borderRadius: "10px",
    flexGrow: 1,
    height: "auto",
    padding: theme.spacing(3),
    position: "fixed",
    overflow: "hidden",
    overflowY: "auto",
    maxHeight: "70vh",
  },
  textField: {
    margin: theme.spacing(1, 0),
  },
  previewContainer: {
    display: "flex",
    minWidth: "300px",
    justifyContent: "center",
  },
  cardRoot: {
    width: "250px",
    padding: theme.spacing(1.5),
    border: `2px solid ${theme.palette.primary.dark}`,
  },
  discountLabel: {
    height: "16px",
    marginBottom: "4px",
    fontSize: "0.75rem",
    "& .MuiChip-label": {
      padding: "0px 8px",
    },
  },
  image: {
    width: "100%",
    height: "220px",
    objectFit: "contain",
  },
  expandButton: {
    margin: theme.spacing(1, 0, 0),
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
}));

const ProductCard = ({ classes, product, images, edit, loading }) => {
  const [expand, setExpand] = useState(false);
  const { user: vendor } = useAuth();

  const imageRender = () => {
    if (images.length <= 0) {
      return placeholder;
    }
    if (edit) {
      return images[0].image;
    }
    return images[0].data;
  };

  const showPricing = () => {
    if (product) {
      if (product.original_price) {
        return (
          <Fragment>
            <span style={{ fontSize: "0.8rem", textDecoration: "line-through" }}>
              {prefixCurrency(product.original_price, vendor)}
            </span>
            <span>{prefixCurrency(product.price, vendor)}</span>
            {product.show_percentage_discount && (
              <Chip
                label={getPercentageDiscount(product.original_price, product.price) + `%`}
                className={classes.discountLabel}
              />
            )}
          </Fragment>
        );
      } else {
        if (product.use_variants && variantOnDiscount(product)) {
          return (
            <Fragment>
              {`From ${prefixCurrency(getLowestPrice(product), vendor)}`}
              <Chip label={`Sale`} className={classes.discountLabel} />
            </Fragment>
          );
        }
        return product.use_variants
          ? `From ${prefixCurrency(getLowestPrice(product), vendor)}`
          : `${prefixCurrency(product.price, vendor)}`;
      }
    }
    return "Price";
  };

  if (loading) {
    return (
      <Card elevation={0} className={classes.cardRoot}>
        <Skeleton animation="wave" variant="text" />
        <Skeleton animation="wave" variant="text" />
        <Skeleton className={classes.image} animation="wave" />
        <Button fullWidth className={classes.expandButton} onClick={() => setExpand(!expand)}>
          <ExpandMore className={expand ? classes.expandOpen : classes.expand} />
        </Button>
        <Collapse in={expand} timeout="auto" unmountOnExit>
          <CardContent>
            <Skeleton animation="wave" variant="text" />
          </CardContent>
        </Collapse>
      </Card>
    );
  }

  return (
    <Card elevation={0} className={classes.cardRoot}>
      <Typography variant="h6">{product.title ? product.title : "Product Name"}</Typography>
      <Typography variant="body1" color="textSecondary">
        {showPricing()}
      </Typography>
      <CardMedia
        className={classes.image}
        image={imageRender()}
        style={{ border: images.length > 0 ? "none" : "2px solid rgba(0,0,0,0.1)" }}
      />
      <Button fullWidth className={classes.expandButton} onClick={() => setExpand(!expand)}>
        <ExpandMore className={expand ? classes.expandOpen : classes.expand} />
      </Button>
      <Collapse in={expand} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography style={{ whiteSpace: "pre-wrap" }} variant="body2">
            {product.description ? product.description : "Description"}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};

const ProductReview = ({ product, images, edit, loading }) => {
  const classes = useStyles();

  return (
    <Paper elevation={3} className={classes.paper}>
      <Typography variant="h5" className={classes.header}>
        Preview
      </Typography>
      <div className={classes.previewContainer}>
        <ProductCard classes={classes} product={product} images={images} edit={edit} loading={loading} />
      </div>
    </Paper>
  );
};

export default ProductReview;
