import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  title: {
    fontWeight: 500,
    color: theme.palette.secondary.main,
    textShadow: `-2px 2px 0 ${theme.palette.primary.main}`,
  },
}));

const PageTitle = ({ title, style }) => {
  const classes = useStyles();
  return (
    <div className={classes.root} style={{ ...style }}>
      <Typography variant="h1" className={classes.title}>
        {title}
      </Typography>
    </div>
  );
};

export default PageTitle;
