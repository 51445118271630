import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { useSnackbar } from "notistack";

import { createVendorFaq } from "../api";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: "10px",
    width: "100%",
  },
}));

const FaqDialog = ({ open, setOpen, setFaqList, faq, setFaq }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleInputChange = (e) => {
    setFaq({
      ...faq,
      [e.target.name]: e.target.value,
    });
  };

  const createFaq = () => {
    if (faq.question === "" || faq.answer === "") {
      enqueueSnackbar(`Question and answer cannot be empty`, { variant: "error" });
      return;
    }

    createVendorFaq(faq)
      .then((res) => {
        setFaqList(res.data);
        setFaq({
          question: "",
          answer: "",
        });
        setOpen(false);
      })
      .catch((err) => enqueueSnackbar(`Error: ${err.response.data}`, { variant: "error" }));
  };

  useEffect(() => {
    setFaq(faq);
  }, [faq, setFaq]);

  const editMode = !!faq.faq_id;

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      TransitionProps={{
        onExited: () =>
          setFaq({
            question: "",
            answer: "",
          }),
      }}
    >
      <DialogTitle>{editMode ? "Update" : "New"} FAQ</DialogTitle>
      <DialogContent>
        <TextField
          label="Question"
          name="question"
          value={faq.question}
          onChange={handleInputChange}
          variant="outlined"
          className={classes.textField}
          margin="dense"
          autoComplete="off"
          autoFocus
        />
        <TextField
          label="Answer"
          name="answer"
          value={faq.answer}
          onChange={handleInputChange}
          variant="outlined"
          className={classes.textField}
          margin="dense"
          autoComplete="off"
          multiline
          minRows={4}
          maxRows={10}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={() => createFaq()}>
          {editMode ? "Update" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FaqDialog;
