import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Switch, Typography } from "@material-ui/core";
import { MeetingRoomTwoTone } from "@material-ui/icons";

import GenericContainer from "../../../components/containers/GenericContainer";
import CollectionOptions from "../components/CollectionOptions";
import CollectionOptionDialog from "../components/CollectionOptionDialog";

import { updateVendorFulfillmentInfo } from "../api";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 400,
    margin: theme.spacing(1, 0, 1),
    flexGrow: 1,
  },
  settingsIcon: {
    marginRight: theme.spacing(1.5),
  },
  optionContainer: {
    margin: theme.spacing(2, 0, 1),
  },
}));

const CollectionSettings = ({ vendor, setVendor, products }) => {
  const classes = useStyles();

  const [collectionOptionDialogOpen, setCollectionOptionDialogOpen] = useState(false);

  return (
    <GenericContainer>
      <div style={{ display: "flex", alignItems: "center" }}>
        <MeetingRoomTwoTone className={classes.settingsIcon} />
        <Typography variant="h5" className={classes.header}>
          Self Collection
        </Typography>
        <Switch
          color="primary"
          checked={vendor.collection}
          onChange={(e) => {
            setVendor({ ...vendor, collection: e.target.checked });
            updateVendorFulfillmentInfo({ ...vendor, collection: e.target.checked });
          }}
        />
      </div>
      <Typography variant="body2" color="textSecondary">
        Set your collection options, addresses and/or instructions.
      </Typography>

      {vendor.collection && (
        <div className={classes.optionContainer}>
          <CollectionOptions vendor={vendor} setVendor={setVendor} products={products} />
          <Button
            variant="contained"
            fullWidth
            color="primary"
            style={{ marginTop: 8 }}
            onClick={() => setCollectionOptionDialogOpen(true)}
          >
            Add Collection Option
          </Button>
        </div>
      )}

      <CollectionOptionDialog
        open={collectionOptionDialogOpen}
        setOpen={setCollectionOptionDialogOpen}
        vendor={vendor}
        setVendor={setVendor}
      />
    </GenericContainer>
  );
};

export default CollectionSettings;
