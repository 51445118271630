import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Chip, Switch, TextField, Typography } from "@material-ui/core";

import GenericContainer from "../../../components/containers/GenericContainer";
import useShopContext from "../../../hooks/useShopContext";
import { ISO_CODES, ISO_COUNTRY_MAP } from "../../../config/countries";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  topSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  header: {
    fontWeight: 400,
    margin: theme.spacing(1, 0, 1),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  subheader: {
    marginBottom: theme.spacing(1),
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1.5, 1),
  },
}));

const ShopGeoBlocking = () => {
  const classes = useStyles();
  const { shopfront, setShopfront } = useShopContext();

  const handleDelete = (country) => {
    let arr = [...shopfront.restricted_countries];
    arr = arr.filter((iso) => iso !== country);
    setShopfront({ ...shopfront, restricted_countries: arr });
  };

  return (
    <GenericContainer>
      <div className={classes.topSection}>
        <Typography variant="h5" className={classes.header}>
          Geo-Blocking
        </Typography>
        <Switch
          style={{ float: "right" }}
          checked={shopfront.geo_blocked}
          onChange={(e) => setShopfront({ ...shopfront, geo_blocked: e.target.checked })}
          color="primary"
        />
      </div>
      {shopfront && shopfront.geo_blocked && (
        <Fragment>
          <Typography variant="body2" color="textSecondary" className={classes.subheader}>
            Restrict visitors from selected countries
          </Typography>
          <Autocomplete
            id="Search countries"
            options={ISO_CODES}
            getOptionLabel={(option) => ISO_COUNTRY_MAP[option]}
            renderOption={(option) => ISO_COUNTRY_MAP[option]}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search countries"
                variant="outlined"
                style={{ marginTop: 8, marginBottom: 24 }}
              />
            )}
            onChange={(event, iso, reason) => {
              if (reason === "select-option") {
                let arr = [...shopfront.restricted_countries];
                arr.push(iso);
                setShopfront({ ...shopfront, restricted_countries: arr });
              }
            }}
            getOptionDisabled={(option) => shopfront.restricted_countries.includes(option)}
          />
          <div className={classes.chipContainer}>
            {shopfront.restricted_countries.map((country, idx) => (
              <Chip
                key={idx}
                color="primary"
                variant="outlined"
                label={ISO_COUNTRY_MAP[country]}
                onDelete={() => handleDelete(country)}
              />
            ))}
          </div>
        </Fragment>
      )}
    </GenericContainer>
  );
};

export default ShopGeoBlocking;
