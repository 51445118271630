export const statusMap = {
  UNCOMPLETED: "Uncompleted",
  PENDING: "Pending",
  PENDING_VERIFICATION: "Pending Verification",
  PAID: "Paid",
  PENDING_DELIVERY: "Pending Delivery",
  COMPLETED: "Completed",
  ABANDONED: "Abandoned",
  FAILED: "Failed",
};

export const paymentMethodMap = {
  DP: "Direct Paynow",
  PN: "PayNow",
  CC: "Credit Card",
  ST: "Stripe",
  CA: "Cash",
  BT: "Bank Transfer",
};

export const statuses = [
  "Uncompleted",
  "Pending",
  "Pending Verification",
  "Paid",
  "Pending Delivery",
  "Completed",
  "Abandoned",
  "Failed",
];

export const statusColors = ["#FFC700", "#ff5500", "#FF8A00", "#0500EB", "#8900eb", "#3DEB00", "#000", "#DC0000"];
