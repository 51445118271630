import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, FormControlLabel, Paper, Typography } from "@material-ui/core";
import { DragHandle } from "@material-ui/icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import GenericContainer from "../../../components/containers/GenericContainer";

import useShopContext from "../../../hooks/useShopContext";
import { fetchProducts, reorderProducts } from "../../ProductList/api";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 400,
    margin: theme.spacing(1, 0, 1),
  },
  subheader: {
    marginBottom: theme.spacing(1),
  },
  productContainer: {
    display: "flex",
    flexDirection: "column",
    borderRadius: theme.spacing(0.5),
  },
  paperRoot: {
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing(0.5, 0),
    padding: theme.spacing(1),
  },
  checkbox: {
    marginBottom: theme.spacing(1),
  },
}));

const ShopProducts = () => {
  const classes = useStyles();
  const { shopfront, setShopfront } = useShopContext();

  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchProducts({ active: true }).then((res) => {
      // console.log(res);
      setProducts(res.data);
    });
  }, []);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    let reorderedProducts = reorder(products, result.source.index, result.destination.index);
    setProducts(reorderedProducts);

    const productIds = reorderedProducts.map((prod) => prod.product_id);
    reorderProducts(productIds)
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const getListStyle = (isDraggingOver) => ({
    padding: 8,
    background: isDraggingOver ? "#FFF2D2" : "#ebebeb",
  });

  return (
    <GenericContainer>
      <Typography variant="h5" className={classes.header}>
        Products Layout
      </Typography>
      <div>
        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox
              color="primary"
              checked={shopfront.reorder_soldout_products}
              onChange={(e) => {
                setShopfront({
                  ...shopfront,
                  reorder_soldout_products: e.target.checked,
                });
              }}
              size="small"
            />
          }
          label="Push sold-out products to bottom"
        />
      </div>
      <Typography variant="body2" color="textSecondary" className={classes.subheader}>
        Drag to re-order your products
      </Typography>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              className={classes.productContainer}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              {products &&
                products.map((product, index) => (
                  <Draggable key={product.product_id} draggableId={product.product_id} index={index}>
                    {(provided, snapshot) => (
                      <Paper className={classes.paperRoot} ref={provided.innerRef} {...provided.draggableProps}>
                        <Typography>{product.title}</Typography>
                        <div {...provided.dragHandleProps}>
                          <DragHandle />
                        </div>
                      </Paper>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </GenericContainer>
  );
};

export default ShopProducts;
