import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: "10px",
    padding: theme.spacing(3),
    maxWidth: 960,
    margin: theme.spacing(2, 0),
    display: "flex",
    flexDirection: "column",
  },
}));

const GenericContainer = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper} {...props} elevation={3}>
      {children}
    </Paper>
  );
};

export default GenericContainer;
