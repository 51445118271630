import { Skeleton } from "@material-ui/lab";

export const defaultColumns = [
  "public_transaction_id",
  "date_created",
  "delivery_option",
  "delivery_date",
  "delivery_recipient_name",
  "collection_option",
  "collection_date",
  "customer_name",
  "total_price",
  "promo_code",
  "payment_method",
  "status",
  "update_status",
  "delivery_address",
  "special_request",
];

export const loadingColumnMapping = {
  public_transaction_id: {
    field: "public_transaction_id",
    headerName: "Order ID",
    sortable: false,
    width: 120,
    renderCell: (params) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40%"} />,
  },
  date_created: {
    field: "date_created",
    headerName: "Order Date",
    sortable: false,
    width: 160,
    renderCell: (params) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40%"} />,
  },
  delivery_option: {
    field: "delivery_option",
    headerName: "Delivery Option",
    sortable: false,
    width: 180,
    renderCell: (params) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40%"} />,
  },
  delivery_date: {
    field: "delivery_date",
    headerName: "Delivery Date",
    sortable: false,
    width: 170,
    renderCell: (params) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40%"} />,
  },
  delivery_recipient_name: {
    field: "delivery_recipient_name",
    headerName: "Recipient Name",
    sortable: false,
    width: 200,
    renderCell: (params) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40%"} />,
  },
  collection_option: {
    field: "collection_option",
    headerName: "Self Collection Option",
    sortable: false,
    width: 220,
    renderCell: (params) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40%"} />,
  },
  collection_date: {
    field: "collection_date",
    headerName: "Self Collection Date",
    sortable: false,
    width: 215,
    renderCell: (params) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40%"} />,
  },
  customer_name: {
    field: "customer_name",
    headerName: "Customer Details",
    sortable: false,
    width: 200,
    renderCell: (params) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40%"} />,
  },
  total_price: {
    field: "total_price",
    headerName: "Order Amount",
    sortable: false,
    width: 150,
    renderCell: (params) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40%"} />,
  },
  promo_code: {
    field: "promo_code",
    headerName: "Promo Code",
    sortable: false,
    width: 150,
    renderCell: (params) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40%"} />,
  },
  payment_method: {
    field: "payment_method",
    headerName: "Payment Method",
    sortable: false,
    width: 200,
    renderCell: (params) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40%"} />,
  },
  status: {
    field: "status",
    headerName: "Order Status",
    sortable: false,
    width: 180,
    renderCell: (params) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40%"} />,
  },
  update_status: {
    field: "transaction_id",
    headerName: "Update Status",
    sortable: false,
    width: 200,
    renderCell: (params) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40%"} />,
  },
  delivery_address: {
    field: "delivery_address",
    headerName: "Delivery Address",
    sortable: false,
    width: 300,
    renderCell: (params) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40%"} />,
  },
  special_request: {
    field: "special_request",
    headerName: "Special Requests",
    sortable: false,
    width: 300,
    renderCell: (params) => <Skeleton animation="wave" variant="text" width={"100%"} height={"40%"} />,
  },
};
