import jwtDecode from "jwt-decode";

import Auth from "../../../Auth";
import { BACKEND_URL, API_VERSION } from "../../../config";

export const updateVendor = (vendor) => {
  return Auth.client.patch(`${BACKEND_URL}/${API_VERSION}/vendors`, vendor);
};

export const updatePassword = (userId, formData) => {
  return Auth.client.patch(`${BACKEND_URL}/v1/users/${userId}`, formData);
};

export const getVendorEngagementSettings = () => {
  const decoded = jwtDecode(Auth.getJWT()) || {};
  return Auth.client.get(`${BACKEND_URL}/v1/users/${decoded.user_id}`).then((res) => {
    return {
      use_special_request: res.data.use_special_request,
      special_request_description: res.data.special_request_description || "",
      use_checkout_message: res.data.use_checkout_message,
      checkout_message: res.data.checkout_message || "",
    };
  });
};

export const updateVendorEngagementSettings = (vendor) => {
  return Auth.client.patch(`${BACKEND_URL}/${API_VERSION}/engagement-settings`, vendor).then((res) => {
    return {
      use_special_request: res.data.use_special_request,
      special_request_description: res.data.special_request_description || "",
      use_checkout_message: res.data.use_checkout_message,
      checkout_message: res.data.checkout_message || "",
    };
  });
};

export const getVendorStripeAccount = () => {
  return Auth.client.get(`${BACKEND_URL}/${API_VERSION}/stripe-account`);
};

export const redirectStripeConnectOnboarding = () => {
  Auth.client
    .get(`${BACKEND_URL}/${API_VERSION}/stripe-onboarding`)
    .then((res) => window.location.assign(res.data.redirect));
};

export const updatePaymentMethods = (paymentSettingsObj) => {
  return Auth.client.put(`${BACKEND_URL}/${API_VERSION}/payment-methods`, paymentSettingsObj);
};

export const fetchVendorFaq = () => {
  return Auth.client.get(`${BACKEND_URL}/${API_VERSION}/faq`);
};

export const createVendorFaq = (faq) => {
  return Auth.client.put(`${BACKEND_URL}/${API_VERSION}/faq`, faq);
};

export const deleteVendorFaq = (faqId) => {
  return Auth.client.delete(`${BACKEND_URL}/${API_VERSION}/faq/${faqId}`);
};

export const reorderVendorFaq = (faqList) => {
  const faqIds = faqList.map((f) => f.faq_id);
  return Auth.client.patch(`${BACKEND_URL}/${API_VERSION}/faq`, faqIds);
};

export const uploadCustomPaynowQr = (customPaynowQr) => {
  const data = new FormData();

  // check if paynowQr is string (uploaded before)
  // or if paynowQr is null (to delete qr)
  if (customPaynowQr && typeof customPaynowQr !== "string") {
    data.append("custom_qr_code", customPaynowQr?.file);
  }
  return Auth.client.patch(`${BACKEND_URL}/${API_VERSION}/custom-paynow-qr`, data);
};
