import validator from "validator";

export const validateElement = (element) => {
  if (element.element_type === "") {
    return { valid: false, errorMsg: "Please select an element type" };
  }

  if (element.element_type === "Text") {
    if (!element.long_description || element.long_description === "") {
      return { valid: false, errorMsg: "Please give a description for the text" };
    }
  } else if (element.element_type === "Link") {
    if (element.title === "") {
      return { valid: false, errorMsg: "Please give a text for the button" };
    }

    if (!element.link || element.link === "") {
      return { valid: false, errorMsg: "Please give a URL for the button" };
    } else if (
      !validator.isURL(element.link, {
        protocols: ["http", "https"],
        require_protocol: true,
        allow_underscores: true,
      })
    ) {
      return { valid: false, errorMsg: "Please give a valid URL for the button" };
    }
  }
  return { valid: true };
};
