import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  IconButton,
  Checkbox,
  FormControlLabel,
  InputAdornment,
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";

import QuestionCard from "./QuestionCard";
import useAuth from "../../../hooks/useAuth";
import { CURRENCY_PREFIX_MAP } from "../../../config/currency";

const styles = (theme) => ({
  optionInput: {
    margin: "0 0 5px 5px",
  },
  optionPriceInput: {
    maxWidth: 120,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 75,
    },
  },
  paper: {
    borderRadius: "10px",
    height: "auto",
    padding: theme.spacing(3),
  },
  header: {
    fontWeight: 400,
    margin: theme.spacing(1, 0, 1),
  },
  subheader: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    margin: theme.spacing(1, 0),
  },
  formControl: {
    margin: theme.spacing(1, 0),
  },
  optionListItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    flex: 1,
    // maxWidth: "calc(100% - 40px)",
  },
  switch: {
    margin: 0,
  },
  editButtons: {
    margin: theme.spacing(3, 0),
    display: "flex",
    justifyContent: "space-between",
  },
  confirmButton: {
    margin: theme.spacing(3, 0),
    color: "#fff",
  },
  button: {
    width: "48%",
  },
  addedQuestionContainer: {
    margin: theme.spacing(3, 0),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    background: "#ebebeb",
  },
  optionLimit: {
    display: "flex",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: 0,
    },
  },
});

const AdditionalQuestions = ({
  classes,
  product,
  setProduct,
  formIsHalfFilled,
  setFormIsHalfFilled,
  enqueueSnackbar,
}) => {
  const [options, setOptions] = useState([]);
  const [question, setQuestion] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [editStatus, setEditStatus] = useState(false);
  const [editQuestionIndex, setEditQuestionIndex] = useState(-1);
  const [required, setRequired] = useState(false);
  const [useOptionLimit, setUseOptionLimit] = useState(false);
  const [optionLimit, setOptionLimit] = useState(1);

  const { user: vendor } = useAuth();
  const currencyPrefix = CURRENCY_PREFIX_MAP[vendor.currency];

  // console.log(options);
  const handleQuestionTypeChange = (e) => {
    setQuestionType(e.target.value);
    setUseOptionLimit(false);
    setOptionLimit(1);
    setRequired(false);

    if (e.target.value === "SE" || e.target.value === "MS") {
      setOptions([{ text: "", price: "" }]);
    }

    if (e.target.value === "SA") {
      setOptions([]);
    }

    if (!formIsHalfFilled) {
      setFormIsHalfFilled(true);
    }
  };

  const handleQuestionInputChange = (e) => {
    setQuestion(e.target.value);

    if (!formIsHalfFilled) {
      setFormIsHalfFilled(true);
    }
  };

  const handleDeleteOption = (index) => {
    // console.log("DELETE: " + index);
    setOptions(options.filter((option) => options.indexOf(option) !== index));
  };

  const handleAddOption = () => {
    setOptions([...options, { text: "" }]);
  };

  const handleOptionInputChange = (e, index) => {
    const values = [...options];
    values[index].text = e.target.value;
    // values[index].inventory_status = true;
    // values[index].price = null;
    setOptions(values);
  };

  const handleOptionPriceChange = (e, index) => {
    const values = [...options];
    if (e.target.value !== "") {
      values[index].price = parseFloat(e.target.value);
    } else {
      values[index].price = null;
    }

    setOptions(values);
  };

  const toggleChecked = () => {
    setRequired((prev) => !prev);
  };

  const duplicatedOptions = () => {
    return options.map((opt) => opt.text).some((value, index, arr) => arr.indexOf(value) !== arr.lastIndexOf(value));
  };

  const addQuestionToList = () => {
    if (duplicatedOptions()) {
      enqueueSnackbar("Option text must be different", { variant: "error" });
      return;
    }

    if (questionType === "MS" && useOptionLimit && optionLimit < 1) {
      enqueueSnackbar("Option limit must be more than 0", { variant: "error" });
      return;
    }

    const values = [...options];
    console.log(values);
    values.forEach((value) => {
      if (value.price) {
        value.price = value.price.toFixed(2);
      } else {
        value.price = null;
      }
    });

    const newQuestion = {
      question_title: question,
      question_type: questionType,
      question_options: values,
      required: required,
      use_option_limit: useOptionLimit,
      option_limit: optionLimit,
    };

    setProduct({
      ...product,
      product_questions: [...product.product_questions, newQuestion],
    });

    setQuestion("");
    setOptions([]);
    setQuestionType("");
    setRequired(false);
    setUseOptionLimit(false);
    setOptionLimit(1);
  };

  const removeAddedQuestion = (index) => {
    setProduct({
      ...product,
      product_questions: product.product_questions.filter((question, i) => i !== index),
    });
  };

  const duplicateQuestion = (index) => {
    const copyOfQuestionList = [...product.product_questions];
    let selectedQuestion = JSON.parse(JSON.stringify(copyOfQuestionList[index]));
    delete selectedQuestion.question_id;

    // remove option id in all options
    selectedQuestion.question_options = selectedQuestion.question_options.map(({ option_id, ...rest }) => rest);

    setProduct({
      ...product,
      product_questions: [...copyOfQuestionList, selectedQuestion],
    });
  };

  const editAddedQuestion = (index) => {
    const selectedQuestion = product.product_questions[index];

    setEditStatus(true);
    setQuestion(selectedQuestion.question_title);
    setRequired(selectedQuestion.required);
    setUseOptionLimit(selectedQuestion.use_option_limit);
    setOptionLimit(selectedQuestion.option_limit);

    // need to use JSON parse and JSON stringify for deep copy of nested array
    // cannot use spread operator (shallow copy) of nested array
    const copy = JSON.parse(JSON.stringify(selectedQuestion.question_options));
    setOptions(copy);

    setQuestionType(selectedQuestion.question_type);
    setEditQuestionIndex(index);
  };

  const updateQuestion = () => {
    // to update question list
    if (duplicatedOptions()) {
      enqueueSnackbar("Option text must be different", { variant: "error" });
      return;
    }

    if (questionType === "MS" && useOptionLimit && optionLimit < 1) {
      enqueueSnackbar("Option limit must be more than 0", { variant: "error" });
      return;
    }

    const values = [...product.product_questions];
    values[editQuestionIndex].question_title = question;
    values[editQuestionIndex].question_type = questionType;
    values[editQuestionIndex].question_options = options;
    values[editQuestionIndex].required = required;
    values[editQuestionIndex].use_option_limit = useOptionLimit;
    values[editQuestionIndex].option_limit = optionLimit;

    // console.log(values[editQuestionIndex].question_options);
    values.map((value) =>
      value.question_options.forEach((option) => {
        // console.log(option.price);
        if (option.price) {
          option.price = parseFloat(option.price).toFixed(2);
        } else {
          option.price = null;
        }
      })
    );
    setProduct({
      ...product,
      product_questions: values,
    });

    // to empty the fields after updating
    setEditStatus(false);
    setQuestion("");
    setOptions([]);
    setQuestionType("");
    setEditQuestionIndex(-1);
    setRequired(false);
    setUseOptionLimit(false);
    setOptionLimit(1);
  };

  const checkIfOptionsEmpty = () => {
    if (options.length > 0) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].text === "") {
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  };

  const AddQuestion = () => {
    return (
      <Button
        className={classes.confirmButton}
        fullWidth
        variant="contained"
        color="primary"
        disabled={questionType === "" || question === "" || checkIfOptionsEmpty()}
        onClick={() => {
          addQuestionToList();
        }}
      >
        Add Question
      </Button>
    );
  };

  const EditQuestion = () => {
    return (
      <div className={classes.editButtons}>
        <Button
          className={classes.button}
          variant="outlined"
          onClick={() => {
            setEditStatus(false);
            setQuestion("");
            setOptions([]);
            setQuestionType("");
            setEditQuestionIndex(-1);
            setRequired(false);
            setUseOptionLimit(false);
            setOptionLimit(0);
          }}
        >
          Cancel
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => updateQuestion()}
          disabled={questionType === "" || question === "" || checkIfOptionsEmpty()}
        >
          Update
        </Button>
      </div>
    );
  };

  const AddedQuestions = () => {
    return (
      <div className={classes.addedQuestionContainer}>
        {product.product_questions.map((question, i) => (
          <QuestionCard
            key={i}
            index={i}
            question={question}
            editQuestionIndex={editQuestionIndex}
            editAddedQuestion={editAddedQuestion}
            removeAddedQuestion={removeAddedQuestion}
            duplicateQuestion={duplicateQuestion}
          />
        ))}
      </div>
    );
  };

  return (
    <Paper elevation={3} className={classes.paper}>
      <Typography variant="h5" className={classes.header}>
        Custom Questions
      </Typography>
      <Typography variant="body2" color="textSecondary" className={classes.subheader}>
        Leave empty if not required
      </Typography>

      {product && product.product_questions.length > 0 && <AddedQuestions />}

      <FormControl variant="outlined" className={classes.formControl} fullWidth>
        <InputLabel>Question Type</InputLabel>
        <Select
          label="Question Type"
          value={questionType}
          onChange={(e) => {
            handleQuestionTypeChange(e);
          }}
        >
          <MenuItem value="">
            <em>Select a question type</em>
          </MenuItem>
          <MenuItem value="SE">Multiple Choice</MenuItem>
          <MenuItem value="MS">Checkboxes</MenuItem>
          <MenuItem value="SA">Short answer</MenuItem>
        </Select>
      </FormControl>

      <TextField
        variant="outlined"
        autoComplete="off"
        name="question"
        label="Question"
        fullWidth
        classes={{ root: classes.textField }}
        value={question}
        onChange={(e) => handleQuestionInputChange(e)}
      />
      <FormControlLabel
        control={<Checkbox checked={required} color="primary" onChange={toggleChecked} />}
        label="Required"
        className={classes.switch}
      />
      {questionType === "MS" && (
        <div className={classes.optionLimit}>
          <FormControlLabel
            control={
              <Checkbox checked={useOptionLimit} color="primary" onChange={() => setUseOptionLimit(!useOptionLimit)} />
            }
            label="Set Option Limit"
            className={classes.switch}
          />
          {useOptionLimit && (
            <TextField
              autoComplete="off"
              type="number"
              label="Option Limit"
              margin="dense"
              inputProps={{
                min: 1,
              }}
              onChange={(e) => setOptionLimit(e.target.value)}
              value={optionLimit}
              style={{ margin: 0 }}
              onWheel={(e) => e.target.blur()}
            />
          )}
        </div>
      )}

      {questionType !== "" && questionType !== "SA" && (
        <div style={{ marginTop: "15px" }}>
          {options &&
            options.map((option, i) => (
              <div key={i} style={{ display: "flex" }}>
                <div className={classes.optionListItem}>
                  <TextField
                    classes={{
                      root: classes.optionInput,
                    }}
                    style={{ flexGrow: 1 }}
                    placeholder={`Enter Option ${i + 1}`}
                    type="text"
                    autoComplete="off"
                    value={option.text}
                    onChange={(e) => handleOptionInputChange(e, i)}
                  />
                  <TextField
                    classes={{
                      root: `${classes.optionInput} ${classes.optionPriceInput}`,
                    }}
                    placeholder={`Price`}
                    type="number"
                    autoComplete="off"
                    value={option.price}
                    onChange={(e) => handleOptionPriceChange(e, i)}
                    helperText="Empty if NA"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+ {currencyPrefix}</InputAdornment>,
                    }}
                    onWheel={(e) => e.target.blur()}
                  />
                </div>
                <div style={{ marginLeft: 8 }}>
                  {options && options.length > 1 && (
                    <IconButton size="small" onClick={() => handleDeleteOption(i)}>
                      <Clear />
                    </IconButton>
                  )}
                </div>
              </div>
            ))}
          <Button variant="outlined" color="primary" onClick={handleAddOption}>
            Add Option
          </Button>
        </div>
      )}
      {!editStatus ? <AddQuestion /> : <EditQuestion />}
    </Paper>
  );
};

export default withStyles(styles)(AdditionalQuestions);
