import React, { useState, Fragment } from "react";
import {
  Typography,
  TextField,
  MenuItem,
  IconButton,
  Radio,
  Checkbox,
  FormControlLabel,
  Menu,
  makeStyles,
  Paper,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";

import useAuth from "../../../hooks/useAuth";
import { CURRENCY_PREFIX_MAP } from "../../../config/currency";

const useStyles = makeStyles((theme) => ({
  disabledInput: {
    "& .MuiInputBase-input.Mui-disabled": {
      fontWeight: 600,
    },
  },
  icon: {
    padding: "4px",
  },
  addedQuestionCard: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(2),
    display: "flex",
  },
  required: {
    color: theme.palette.error.main,
  },
  limitAnnotation: {
    fontSize: "0.9rem",
    color: theme.palette.grey[700],
  },
}));

const QuestionCard = ({
  question,
  index,
  editQuestionIndex,
  editAddedQuestion,
  removeAddedQuestion,
  duplicateQuestion,
}) => {
  const classes = useStyles();
  const { user: vendor } = useAuth();

  const currencyPrefix = CURRENCY_PREFIX_MAP[vendor.currency];

  const [anchor, setAnchor] = useState(null);

  const open = Boolean(anchor);

  const handleOpenMenu = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchor(null);
  };

  return (
    <Paper className={classes.addedQuestionCard}>
      <div style={{ flexGrow: 1 }}>
        <Typography
          variant="h6"
          style={{
            fontWeight: 500,
            wordWrap: "break-word",
            overflowWrap: "break-word",
            whiteSpace: "normal",
            maxWidth: "100%",
            wordBreak: "break-all",
          }}
        >
          {question.question_title} {question.required && <span className={classes.required}>*</span>}{" "}
          {question.use_option_limit && <span className={classes.limitAnnotation}>Limit: {question.option_limit}</span>}
        </Typography>

        {question.question_options &&
          question.question_options.length > 0 &&
          question.question_options.map((option, i) => {
            if (question.question_type === "SE") {
              return (
                <FormControlLabel
                  disabled
                  label={`${option.text}${option.price ? `, ${currencyPrefix}${option.price}` : ""}`}
                  control={<Radio />}
                  key={i}
                />
              );
            } else if (question.question_type === "MS") {
              return (
                <FormControlLabel
                  disabled
                  label={`${option.text}${option.price ? `, ${currencyPrefix}${option.price}` : ""}`}
                  control={<Checkbox />}
                  key={i}
                />
              );
            }
            return <Fragment />;
          })}

        {question.question_type === "SA" && (
          <TextField disabled placeholder="Short-answer text" className={classes.disabledInput} />
        )}
      </div>

      <div style={{ float: "right", display: "flex", flexDirection: "column" }}>
        <IconButton
          classes={{ root: classes.icon }}
          onClick={handleOpenMenu}
          disabled={index === editQuestionIndex}
          size="small"
          id="qn-menu-button"
          aria-controls="qn-menu"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="qn-menu"
          aria-labelledby="qn-menu-button"
          anchorEl={anchor}
          open={open}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          getContentAnchorEl={null}
        >
          <MenuItem
            onClick={(e) => {
              handleCloseMenu(e);
              editAddedQuestion(index);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              handleCloseMenu(e);
              removeAddedQuestion(index);
            }}
          >
            Remove
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              handleCloseMenu(e);
              duplicateQuestion(index);
            }}
          >
            Duplicate
          </MenuItem>
        </Menu>
      </div>
    </Paper>
  );
};

export default QuestionCard;
