import React from "react";
import { Typography, Button } from "@material-ui/core";
import theme from "../theme";

const NotFound = () => {
  return (
    <div
      style={{
        backgroundColor: theme.palette.background.dark,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        flexGrow: 1,
        minHeight: "100%",
      }}
    >
      <Typography variant="h3" style={{ margin: "10px 0" }}>
        404 Not Found
      </Typography>
      <a href="https://carteapp.io">
        <Button variant="contained" color="secondary">
          Back to Home
        </Button>
      </a>
    </div>
  );
};

export default NotFound;
