import React, { useState, useEffect } from "react";
import { makeStyles, Typography, TextField, Button, InputAdornment } from "@material-ui/core";
import { Facebook, Instagram, Telegram, WhatsApp, Link } from "@material-ui/icons";
import { useSnackbar } from "notistack";

import GenericContainer from "../../../components/containers/GenericContainer";

import { updateVendor } from "../api";
import useAuth from "../../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 400,
    margin: theme.spacing(1, 0),
  },
  headerDescription: {
    marginBottom: "12px",
  },
  saveButton: {
    width: 150,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  textField: {
    marginBottom: theme.spacing(1.5),
    borderRadius: "5px",
    width: "100%",
  },
  inputAdornment: {
    color: theme.palette.grey[600],
    display: "flex",
    gap: theme.spacing(0.5),
  },
}));

const LinkSettings = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { user, refresh } = useAuth();

  const [vendorLinks, setVendorLinks] = useState({
    facebook_link: "",
    telegram_link: "",
    whatsapp_link: "",
    instagram_link: "",
    generic_link: "",
  });

  const handleUpdate = () => {
    updateVendor({
      facebook_link: vendorLinks.facebook_link !== "" ? `https://facebook.com/${vendorLinks.facebook_link}` : "",
      telegram_link: vendorLinks.telegram_link !== "" ? `https://t.me/${vendorLinks.telegram_link}` : "",
      whatsapp_link: vendorLinks.whatsapp_link !== "" ? `https://wa.me/${vendorLinks.whatsapp_link}` : "",
      instagram_link: vendorLinks.instagram_link !== "" ? `https://instagram.com/${vendorLinks.instagram_link}` : "",
      generic_link: vendorLinks.generic_link,
    })
      .then((res) => {
        enqueueSnackbar("Links updated!", { variant: "success" });
      })
      .catch((err) => {
        enqueueSnackbar(`Oops, something went wrong (${err.response.status})`, { variant: "error" });
      })
      .finally(() => refresh());
  };

  const handleInputChange = (e) => {
    setVendorLinks({
      ...vendorLinks,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setVendorLinks({
      instagram_link: user.instagram_link?.replace("https://instagram.com/", "") || "",
      whatsapp_link: user.whatsapp_link?.replace("https://wa.me/", "") || "",
      facebook_link: user.facebook_link?.replace("https://facebook.com/", "") || "",
      telegram_link: user.telegram_link?.replace("https://t.me/", "") || "",
      generic_link: user.generic_link ? user.generic_link : "",
    });
  }, [user]);

  return (
    <GenericContainer>
      <Typography variant="h5" className={classes.header}>
        Social Links
      </Typography>
      <Typography variant="body1" color="textSecondary" className={classes.headerDescription}>
        These links will be displayed on your shopfront. Leave blank if not needed.
      </Typography>

      <TextField
        label="Instagram"
        type="url"
        name="instagram_link"
        value={vendorLinks.instagram_link}
        onChange={handleInputChange}
        variant="outlined"
        className={classes.textField}
        margin="dense"
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.inputAdornment}>
              <Instagram />
              <Typography color="textSecondary">https://instagram.com/</Typography>
            </InputAdornment>
          ),
        }}
        helperText="Instagram username"
      />
      <TextField
        label="WhatsApp"
        type="url"
        name="whatsapp_link"
        value={vendorLinks.whatsapp_link}
        onChange={handleInputChange}
        variant="outlined"
        className={classes.textField}
        margin="dense"
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.inputAdornment}>
              <WhatsApp />
              <Typography>https://wa.me/</Typography>
            </InputAdornment>
          ),
        }}
        helperText="Whatsapp number and country code without spaces e.g. 6598765432"
      />
      <TextField
        label="Facebook"
        type="url"
        name="facebook_link"
        value={vendorLinks.facebook_link}
        onChange={handleInputChange}
        variant="outlined"
        className={classes.textField}
        margin="dense"
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.inputAdornment}>
              <Facebook />
              <Typography>https://facebook.com/</Typography>
            </InputAdornment>
          ),
        }}
        helperText="Facebook page name e.g. carteappsg"
      />
      <TextField
        label="Telegram"
        type="url"
        name="telegram_link"
        value={vendorLinks.telegram_link}
        onChange={handleInputChange}
        variant="outlined"
        className={classes.textField}
        margin="dense"
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.inputAdornment}>
              <Telegram />
              <Typography color="textSecondary">https://t.me/</Typography>
            </InputAdornment>
          ),
        }}
        helperText="Telegram username e.g. carteappio"
      />
      <TextField
        label="Generic"
        type="url"
        name="generic_link"
        value={vendorLinks.generic_link}
        onChange={handleInputChange}
        variant="outlined"
        className={classes.textField}
        margin="dense"
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.inputAdornment}>
              <Link />
            </InputAdornment>
          ),
        }}
        helperText="Anything you like here"
      />

      <div className={classes.buttonContainer}>
        <Button variant="contained" color="primary" className={classes.saveButton} onClick={handleUpdate}>
          Save
        </Button>
      </div>
    </GenericContainer>
  );
};

export default LinkSettings;
