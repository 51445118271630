import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";

const DeletePromoDialog = ({ deleteDialog, setDeleteDialog, setDeletePromoId, handleDeletePromoCode }) => {
  return (
    <Dialog
      open={deleteDialog}
      onClose={() => {
        setDeleteDialog(false);
        setDeletePromoId("");
      }}
    >
      <DialogTitle>Delete Promo Code?</DialogTitle>
      <DialogContent>This action cannot be undone, are you sure you want to delete?</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            setDeleteDialog(false);
            setDeletePromoId("");
          }}
        >
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={() => handleDeletePromoCode()}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePromoDialog;
