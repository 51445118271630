import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { isAfter, startOfDay } from "date-fns";

import Auth from "../../../Auth";
import { BACKEND_URL, API_VERSION } from "../../../config";

export const fetchVendorFulfillmentInfo = () => {
  const user = jwtDecode(Cookies.get("t1"));
  return Auth.client.get(`${BACKEND_URL}/${API_VERSION}/users/${user.user_id}`);
};

export const updateVendorFulfillmentInfo = (vendor) => {
  return Auth.client.patch(`${BACKEND_URL}/${API_VERSION}/fulfillment-settings`, vendor);
};

export const updateDaysForAdvancedorders = (vendor) => {
  return Auth.client.patch(`${BACKEND_URL}/${API_VERSION}/vendors`, { days_in_advance: vendor.days_in_advance });
};

export const updateVendorDeliveryScheduleInfo = (
  blockedDatesDelivery,
  blockedWeekDaysDelivery,
  blockedDatesSelfCollection,
  blockedWeekDaysSelfCollection,
  maxDateRange
) => {
  const today = new Date();

  return Auth.client.patch(`${BACKEND_URL}/${API_VERSION}/fulfillment-settings`, {
    blocked_dates_delivery: blockedDatesDelivery
      .filter((date) => isAfter(date, today))
      .map((date) => startOfDay(date).toISOString()),
    blocked_week_days_delivery: blockedWeekDaysDelivery,
    blocked_dates_self_collection: blockedDatesSelfCollection
      .filter((date) => isAfter(date, today))
      .map((date) => startOfDay(date).toISOString()),
    blocked_week_days_self_collection: blockedWeekDaysSelfCollection,
    max_date_range: maxDateRange,
  });
};

export const createCollectionOption = (collectionOption) => {
  return Auth.client.post(`${BACKEND_URL}/${API_VERSION}/collection-options`, collectionOption);
};

export const updateCollectionOption = (collectionOption) => {
  return Auth.client.put(
    `${BACKEND_URL}/${API_VERSION}/collection-options/${collectionOption.collection_option_id}`,
    collectionOption
  );
};

export const deleteCollectionOption = (collectionOption) => {
  return Auth.client.delete(
    `${BACKEND_URL}/${API_VERSION}/collection-options/${collectionOption.collection_option_id}`
  );
};

export const createDeliveryOption = (deliveryOption) => {
  return Auth.client.post(`${BACKEND_URL}/${API_VERSION}/delivery-options`, deliveryOption);
};

export const updateDeliveryOption = (deliveryOption) => {
  return Auth.client.put(
    `${BACKEND_URL}/${API_VERSION}/delivery-options/${deliveryOption.delivery_option_id}`,
    deliveryOption
  );
};

export const deleteDeliveryOption = (deliveryOption) => {
  return Auth.client.delete(`${BACKEND_URL}/${API_VERSION}/delivery-options/${deliveryOption.delivery_option_id}`);
};
