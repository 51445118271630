import React from "react";
import { makeStyles, Paper, Radio, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    border: ({ selected }) =>
      selected ? `1.5px solid ${theme.palette.primary.main}` : `1.5px solid ${theme.palette.grey[300]}`,
    flex: 1,
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const SubscriptionOption = (props) => {
  const { value, title, plan, setPlan, subtitle } = props;
  const selected = value === plan;
  const classes = useStyles({ selected });

  return (
    <Paper className={classes.root} elevation={0} onClick={() => setPlan(value)}>
      <Radio checked={selected} color="primary" />
      <div>
        <Typography variant="subtitle2" color="textSecondary">
          {value.toUpperCase()}
        </Typography>
        <Typography variant="h6">
          {title}
          <span style={{ fontSize: "0.8rem" }}>{subtitle}</span>
        </Typography>
      </div>
    </Paper>
  );
};

export default SubscriptionOption;
