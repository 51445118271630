import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { DropzoneAreaBase } from "material-ui-dropzone";
import { Close, Delete, Image } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
  },
  formControl: {
    margin: theme.spacing(1, 0),
  },
  helperText: {
    margin: "4px 14px 0 14px",
    fontSize: "0.75rem",
    fontWeight: 400,
    lineHeight: 1.66,
  },
  textField: {
    marginBottom: "10px",
    width: "100%",
  },
  dropzoneContainer: {
    margin: theme.spacing(1, 0, 0, 0),
    minHeight: "100px",
  },
  imageContainer: {
    display: "flex",
    marginTop: theme.spacing(1),
    justifyContent: "center",
  },
  image: {
    objectFit: "contain",
    height: "130px",
  },
  deleteImageButton: {
    position: "relative",
    top: -10,
    right: 20,
    height: 36, // there is min height 36px
    width: 35,
  },
}));

const TextElementFields = ({ element, setElement, handleInputChange, classes }) => {
  const renderImageDropzone = (Array.isArray(element.image) && element.image.length === 0) || !element.image;

  return (
    <>
      <TextField
        label="Title"
        name="title"
        placeholder="i.e., About Us"
        value={element.title}
        onChange={handleInputChange}
        variant="outlined"
        className={classes.textField}
        margin="dense"
        autoComplete="off"
      />
      {renderImageDropzone ? (
        <>
          <DropzoneAreaBase
            dropzoneText=""
            dropzoneClass={classes.dropzoneContainer}
            acceptedFiles={["image/*"]}
            filesLimit={1}
            Icon={Image}
            alertSnackbarProps={{
              anchorOrigin: { vertical: "top", horizontal: "right" },
            }}
            maxFileSize={1000000} // allow up to 1MB
            showPreviewsInDropzone={false}
            onAdd={(newPhoto) => setElement({ ...element, image: [].concat(newPhoto) })}
            onDelete={(deletePhotoObj) => {
              setElement({ ...element, image: [] });
            }}
          />
          <DialogContentText className={classes.helperText}>
            Add image to element. Recommended Ratio: 1x1
          </DialogContentText>
        </>
      ) : (
        <div className={classes.imageContainer}>
          <img
            className={classes.image}
            src={Array.isArray(element.image) ? element.image[0].data : element.image}
            alt="custom-link"
          />
          <Fab
            disableRipple
            className={classes.deleteImageButton}
            color="primary"
            onClick={() => setElement({ ...element, image: [] })}
          >
            <Delete color="inherit" style={{ fontSize: "1rem" }} />
          </Fab>
        </div>
      )}
      <TextField
        label="Text Body"
        name="long_description"
        placeholder="Use this for a longer and more descriptive paragraph"
        value={element.long_description}
        onChange={handleInputChange}
        variant="outlined"
        className={classes.textField}
        margin="dense"
        autoComplete="off"
        multiline
        minRows={3}
        maxRows={8}
      />
    </>
  );
};

const ExternalLinkElementFields = ({ element, setElement, handleInputChange, classes }) => {
  const renderImageDropzone = (Array.isArray(element.image) && element.image.length === 0) || !element.image;

  return (
    <>
      <TextField
        label="Button Text"
        required
        name="title"
        placeholder="i.e., Youtube Channel"
        value={element.title}
        onChange={handleInputChange}
        variant="outlined"
        className={classes.textField}
        margin="dense"
        autoComplete="off"
      />
      <TextField
        label="URL"
        required
        name="link"
        placeholder="https://www.youtube.com/"
        value={element.link}
        onChange={handleInputChange}
        variant="outlined"
        className={classes.textField}
        margin="dense"
        autoComplete="off"
        multiline
        minRows={1}
      />
      {renderImageDropzone ? (
        <>
          <DropzoneAreaBase
            dropzoneText=""
            dropzoneClass={classes.dropzoneContainer}
            acceptedFiles={["image/*"]}
            filesLimit={1}
            Icon={Image}
            alertSnackbarProps={{
              anchorOrigin: { vertical: "top", horizontal: "right" },
            }}
            maxFileSize={1000000} // allow up to 1MB
            showPreviewsInDropzone={false}
            onAdd={(newPhoto) => setElement({ ...element, image: [].concat(newPhoto) })}
            onDelete={(deletePhotoObj) => {
              setElement({ ...element, image: [] });
            }}
          />
          <DialogContentText className={classes.helperText}>
            Add a button image. Recommended Ratio: 1x1
          </DialogContentText>
        </>
      ) : (
        <div className={classes.imageContainer}>
          <img
            className={classes.image}
            src={Array.isArray(element.image) ? element.image[0].data : element.image}
            alt="custom-link"
          />
          <Fab
            disableRipple
            className={classes.deleteImageButton}
            color="primary"
            onClick={() => setElement({ ...element, image: [] })}
          >
            <Delete color="inherit" style={{ fontSize: "1rem" }} />
          </Fab>
        </div>
      )}
    </>
  );
};

const InternalLinkElementFields = ({ element, setElement, handleInputChange, classes }) => {
  return (
    <TextField
      label="Button Text"
      required
      name="title"
      placeholder="Enter Shop"
      value={element.title}
      onChange={handleInputChange}
      variant="outlined"
      className={classes.textField}
      margin="dense"
      autoComplete="off"
    />
  );
};

const ElementDialog = ({ open, setOpen, element, setElement, handleCreateElement, handleEditElement }) => {
  const classes = useStyles();

  const editMode = !!element.element_id;

  const handleInputChange = (e) => {
    setElement({
      ...element,
      [e.target.name]: e.target.value,
    });
  };

  const renderFields = () => {
    switch (element.element_type) {
      case "Text":
        return (
          <TextElementFields
            element={element}
            setElement={setElement}
            handleInputChange={handleInputChange}
            classes={classes}
          />
        );
      case "Link":
        return (
          <ExternalLinkElementFields
            element={element}
            setElement={setElement}
            handleInputChange={handleInputChange}
            classes={classes}
          />
        );
      case "Route":
        return (
          <InternalLinkElementFields
            element={element}
            setElement={setElement}
            handleInputChange={handleInputChange}
            classes={classes}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => setOpen(false)}
      TransitionProps={{
        onExited: () =>
          setElement({
            title: "",
            order: 0,
            element_type: "",
            image: [],
          }),
      }}
    >
      <div className={classes.dialogHeader}>
        <DialogTitle>{editMode ? "Update" : "Add"} Landing Page Element</DialogTitle>
        <IconButton size="small" onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </div>
      <DialogContent className={classes.dialogContent}>
        <FormControl variant="outlined" fullWidth className={classes.formControl} margin="dense">
          <InputLabel>Type</InputLabel>
          <Select
            label="Type"
            name="element_type"
            value={element.element_type}
            disabled={element.element_type === "Route"}
            onChange={handleInputChange}
          >
            <MenuItem value="Text">Text</MenuItem>
            <MenuItem value="Link">Link Button</MenuItem>
            <MenuItem value="Route" disabled>
              Shop Link
            </MenuItem>
          </Select>
        </FormControl>
        {renderFields()}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => (editMode ? handleEditElement() : handleCreateElement())}
        >
          {editMode ? "Update" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ElementDialog;
