import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Grow, CircularProgress, Link, TextField } from "@material-ui/core";
import { Clear, Done } from "@material-ui/icons";
import { useSnackbar } from "notistack";

import useAuth from "../../../hooks/useAuth";
import GenericContainer from "../../../components/containers/GenericContainer";
import { applyPromoToExistingPremium, checkoutToSubscribe, viewSubscription } from "../api";
import SubscriptionOption from "./SubscriptionOption";
import { PREMIUM_PLANS } from "../helpers/config";

const useStyles = makeStyles((theme) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    margin: theme.spacing(3, 0, 1),
    gap: theme.spacing(2),
  },
  header: {
    fontWeight: 400,
    margin: theme.spacing(1, 0, 1),
  },
  iconOuterContainer: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: "100px",
    padding: "5px",
  },
  iconMiddleContainer: {
    backgroundColor: theme.palette.primary.main,
    padding: "8px",
    borderRadius: "100px",
  },
  iconInnerContainer: {
    width: "100px",
    height: "100px",
    borderRadius: "50px",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.common.white,
  },
  iconOuterContainer1: {
    border: `2px solid ${theme.palette.grey[600]}`,
    borderRadius: "100px",
    padding: "5px",
  },
  iconMiddleContainer1: {
    backgroundColor: `${theme.palette.grey[600]}`,
    padding: "8px",
    borderRadius: "100px",
  },
  iconInnerContainer1: {
    width: "100px",
    height: "100px",
    borderRadius: "50px",
    backgroundColor: `${theme.palette.grey[600]}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.common.white,
  },
  actionSection: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  subscriptionPlan: {
    display: "flex",
    width: "100%",
    alignItems: "stretch",
    justifyContent: "center",
    gap: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  promoCodeFieldForBasic: {
    width: 200,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  promoCodeFieldForPremium: {
    margin: 0,
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      flex: 2,
    },
  },
  applyButton: {
    [theme.breakpoints.down("sm")]: {
      flex: 1,
    },
  },
  primaryButton: {
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      marginRight: "auto",
      width: "100%",
    },
  },
}));

const SubscriptionDetails = ({ subscribed, loading }) => {
  const classes = useStyles();
  const { user: vendor } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [promoCode, setPromoCode] = useState("");
  const [revealField, setRevealField] = useState(false);
  const [applying, setApplying] = useState(false);
  const [plan, setPlan] = useState("sgd");

  const cancelRecurringSubscription = vendor && vendor.cancel_at_period_end;

  const getBillingEndPeriod = () => {
    if (vendor && vendor.current_billing_period_end) {
      // console.log(vendor.current_billing_period_end);
      const billingEndDate = new Date(vendor.current_billing_period_end).toDateString();
      const billingEndTime = new Date(vendor.current_billing_period_end).toTimeString().substring(0, 5);
      return billingEndDate + ", " + billingEndTime;
    }
    return null;
  };

  const applyPromoCode = () => {
    setApplying(true);
    applyPromoToExistingPremium(promoCode.toUpperCase())
      .then((res) => {
        // console.log(res);
        setPromoCode("");
        enqueueSnackbar("Promo code applied successfully", { variant: "success" });
      })
      .catch((err) => {
        // console.log(err);
        enqueueSnackbar(err.response.data, { variant: "error" });
      })
      .finally(() => setApplying(false));
  };

  if (loading) {
    return (
      <GenericContainer>
        <Typography variant="h5" className={classes.header}>
          Your Plan
        </Typography>
        <CircularProgress style={{ marginTop: "24px", marginBottom: "16px", alignSelf: "center" }} />
        <Typography variant="body1" style={{ textAlign: "center" }}>
          Hold on while we retrieve your information...
        </Typography>
      </GenericContainer>
    );
  }

  return (
    <Fragment>
      <GenericContainer>
        <Typography variant="h5" className={classes.header}>
          Your Plan
        </Typography>
        <div className={classes.body}>
          {subscribed ? (
            <Fragment>
              <div style={{ marginBottom: "8px" }}>
                <Grow in={true} timeout={1000}>
                  <div className={classes.iconOuterContainer}>
                    <div className={classes.iconMiddleContainer}>
                      <div className={classes.iconInnerContainer}>
                        <Done fontSize="large" />
                      </div>
                    </div>
                  </div>
                </Grow>
              </div>
              <Typography variant="h6">Hooray! 🎉 You're currently on our Premium Plan.</Typography>
              {cancelRecurringSubscription ? (
                <Typography variant="body1" style={{ textAlign: "center" }}>
                  Your plan is ending on <span style={{ fontWeight: 500 }}>{getBillingEndPeriod()}</span>.
                </Typography>
              ) : (
                <Typography variant="body1" style={{ textAlign: "center" }}>
                  Your plan will be renewed automatically on{" "}
                  <span style={{ fontWeight: 500 }}>{getBillingEndPeriod()}</span>.
                </Typography>
              )}

              <div className={classes.actionSection}>
                {!revealField && (
                  <Link style={{ cursor: "pointer" }} onClick={() => setRevealField(true)}>
                    Have a Promo Code? Click here to apply
                  </Link>
                )}
                {revealField && (
                  <div style={{ display: "flex", flex: 2, alignItems: "center", gap: "8px", width: "100%" }}>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      className={classes.promoCodeFieldForPremium}
                      placeholder="Enter Promo Code"
                      value={promoCode}
                      onChange={(e) => setPromoCode(e.target.value)}
                    />
                    {applying ? (
                      <Button
                        className={classes.applyButton}
                        variant="contained"
                        color="primary"
                        startIcon={<CircularProgress size="1.5rem" color="inherit" />}
                      >
                        Applying
                      </Button>
                    ) : (
                      <Button
                        className={classes.applyButton}
                        variant="contained"
                        color="primary"
                        onClick={() => applyPromoCode()}
                        disabled={!promoCode || promoCode === ""}
                      >
                        Apply
                      </Button>
                    )}
                  </div>
                )}

                <Button
                  className={classes.primaryButton}
                  variant="contained"
                  color="primary"
                  onClick={() => viewSubscription()}
                >
                  Manage Subscription
                </Button>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div style={{ marginBottom: "8px" }}>
                <Grow in={true} timeout={1000}>
                  <div className={classes.iconOuterContainer1}>
                    <div className={classes.iconMiddleContainer1}>
                      <div className={classes.iconInnerContainer1}>
                        <Clear fontSize="large" />
                      </div>
                    </div>
                  </div>
                </Grow>
              </div>
              <Typography variant="h6">You're currently on our Basic Plan.</Typography>
              <Typography variant="body1" color="textSecondary">
                Looking to take your shop to the next level? Upgrade to enjoy unlimited transactions and exclusive
                features!
              </Typography>
              <Link href="https://carteapp.io#pricing">View Plans</Link>
              <div className={classes.actionSection}>
                <Typography color="textSecondary" variant="subtitle1">
                  Select Currency Plan
                </Typography>
                <div className={classes.subscriptionPlan}>
                  {PREMIUM_PLANS.map((p) => (
                    <SubscriptionOption {...p} plan={plan} setPlan={setPlan} />
                  ))}
                </div>
                <Typography color="textSecondary" variant="subtitle1" style={{ marginTop: 8 }}>
                  Promo Code
                </Typography>
                <TextField
                  variant="outlined"
                  margin="dense"
                  className={classes.promoCodeFieldForBasic}
                  placeholder="Enter Promo Code"
                  value={promoCode}
                  onChange={(e) => setPromoCode(e.target.value)}
                />
                <Button
                  className={classes.primaryButton}
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    checkoutToSubscribe(promoCode, plan).catch((err) =>
                      enqueueSnackbar("Something went wrong, DM us on Instagram for help", { variant: "error" })
                    )
                  }
                >
                  Upgrade to Premium
                </Button>
              </div>
            </Fragment>
          )}
        </div>
      </GenericContainer>
    </Fragment>
  );
};

export default SubscriptionDetails;
