import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const styles = (theme) => ({
  table: {
    minWidth: 750,
    fontSize: "14px",
  },
  header: {
    fontWeight: 600,
    fontSize: "16px",
  },
  status: {
    display: "block",
    width: "80%",
    borderRadius: "8px",
    textAlign: "center",
    color: "#fff",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  tableCell: {
    fontSize: "14px",
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
  },
  hover: {
    cursor: "pointer",
  },
  paginationSection: {
    height: 52,
    padding: theme.spacing(1, 2),
  },
});

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, handleRequestSort, headCells } = props;

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow className={classes.tableCell}>
        {headCells.map((headCell, i) => (
          <TableCell key={i} sortDirection={orderBy === headCell.id ? order : false} classes={{ root: classes.header }}>
            {headCell.sort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const TableListing = (props) => {
  const {
    classes,
    headCells,
    rows,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    pageNum,
    setPageNum,
    totalCount,
    variable,
    hover,
    rowOnClick,
    loading,
  } = props;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handlePageChange = (event, newPage) => {
    setPageNum(newPage);
  };

  const formatDate = (date) => {
    if (date !== null) {
      const newDate = new Date(date).toLocaleDateString("en-GB");
      // console.log(newDate);
      return newDate;
    }
    return "-";
  };

  if (loading) {
    return (
      <Paper>
        <TableContainer>
          <Table classes={{ root: classes.table }} size="medium">
            <colgroup>
              {headCells && headCells.map((header, i) => <col key={i} style={{ width: header.width }} />)}
            </colgroup>
            <EnhancedTableHead
              classes={classes}
              handleRequestSort={handleRequestSort}
              order={order}
              orderBy={orderBy}
              headCells={headCells}
            />
            <TableBody>
              {[...Array(6)].map((item, rowIdx) => (
                <TableRow key={rowIdx}>
                  {headCells.map((cell, cellIdx) => (
                    <TableCell key={cellIdx} className={classes.tableCell}>
                      <Skeleton animation="wave" variant="text" width={"100%"} height={"100%"} />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPage={6}
          rowsPerPageOptions={[]}
          page={pageNum}
          count={totalCount}
          onPageChange={handlePageChange}
        />
      </Paper>
    );
  }

  return (
    <Paper>
      <TableContainer>
        <Table classes={{ root: classes.table }} size="medium">
          <colgroup>
            {headCells && headCells.map((header, i) => <col key={i} style={{ width: header.width }} />)}
          </colgroup>
          <EnhancedTableHead
            classes={classes}
            handleRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
            headCells={headCells}
          />
          <TableBody>
            {rows.length > 0 ? (
              rows.map((row, rowIdx) => {
                return (
                  <TableRow
                    key={rowIdx}
                    hover={hover}
                    classes={{ hover: hover ? classes.hover : "" }}
                    onClick={(event) => {
                      if (rowOnClick) {
                        rowOnClick(row);
                      }
                      return;
                    }}
                  >
                    {headCells.map((cell, cellIdx) => {
                      if (cell.date) {
                        return (
                          <TableCell key={cellIdx} className={classes.tableCell}>
                            {formatDate(row[cell.id])}
                          </TableCell>
                        );
                      }
                      if (cell.isFunction) {
                        return (
                          <TableCell key={cellIdx} className={classes.tableCell}>
                            {cell.isFunction.argument
                              ? cell.isFunction.function(row[cell.isFunction.argument])
                              : cell.isFunction.function(row)}
                          </TableCell>
                        );
                      }
                      if (cell.component) {
                        const Child = cell.component;
                        return (
                          <TableCell key={cellIdx} className={classes.tableCell}>
                            <Child product={row} />
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={cellIdx} className={classes.tableCell}>
                          {row[cell.id]}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={headCells.length} style={{ textAlign: "center" }}>
                  <Typography>No {variable}.</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPage={6}
        rowsPerPageOptions={[]}
        page={pageNum}
        count={totalCount}
        onPageChange={handlePageChange}
      />
    </Paper>
  );
};

export default withStyles(styles)(TableListing);
