import React, { useState } from "react";
import { makeStyles, Typography, Paper, Menu, IconButton, MenuItem } from "@material-ui/core";
import { DragHandle, MoreVert } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    display: "flex",
    padding: theme.spacing(1.5),
    gap: theme.spacing(1),
    overflowWrap: "break-word",
  },
  leftContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  faqBody: {
    whiteSpace: "pre-line",
  },
}));

const FaqCard = ({ faq, provided, selectFaqForEdit, deleteFaq }) => {
  const classes = useStyles();

  const [anchor, setAnchor] = useState(null);
  const open = Boolean(anchor);

  const handleOpenMenu = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchor(null);
  };

  return (
    <Paper className={classes.paperContainer} ref={provided.innerRef} {...provided.draggableProps}>
      <div className={classes.leftContainer}>
        <Typography variant="body1" gutterBottom>
          {faq.question}
        </Typography>
        <Typography className={classes.faqBody} variant="body2" color="textSecondary">
          {faq.answer.length > 200 ? faq.answer.slice(0, 200) + "..." : faq.answer}
        </Typography>
      </div>
      <div className={classes.rightContainer}>
        <div {...provided.dragHandleProps}>
          <DragHandle />
        </div>
        <IconButton
          classes={{ root: classes.icon }}
          onClick={handleOpenMenu}
          size="small"
          id="faq-menu-button"
          aria-controls="faq-menu"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="faq-menu"
          aria-labelledby="faq-menu-button"
          anchorEl={anchor}
          open={open}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          getContentAnchorEl={null}
        >
          <MenuItem
            onClick={() => {
              selectFaqForEdit(faq);
              handleCloseMenu();
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              deleteFaq(faq.faq_id);
              handleCloseMenu();
            }}
          >
            Remove
          </MenuItem>
        </Menu>
      </div>
    </Paper>
  );
};

export default FaqCard;
