import React from "react";
import { Calendar as ReactCalendar } from "react-calendar";

import "./Calendar.css";

const Calendar = (props) => {
  return <ReactCalendar {...props} />;
};

export default Calendar;
