import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  typography: {
    color: "#F4BF01",
    WebkitTextFillColor: "#F4BF01",
    textShadow: ({ border, shadow }) =>
      `-${border}px 0px #064B72, 0 ${border}px #064B72, ${border}px 0px #064B72, 0 -${border}px #064B72, -${shadow}px ${shadow}px 0 #DE5158`,
  },
}));

const StyledTypography = ({ children, border = 2, shadow = 4, ...props }) => {
  const classes = useStyles({ border, shadow });
  return (
    <span className={classes.typography} {...props}>
      {children}
    </span>
  );
};

export default StyledTypography;
