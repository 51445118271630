import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import DeliveryOptionCard from "./DeliveryOptionCard";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    background: "#ebebeb",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
}));

const DeliveryOptions = ({ deliveryOptions, vendor, setVendor, products }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {deliveryOptions &&
        deliveryOptions.map((option) => (
          <DeliveryOptionCard
            key={option.delivery_option_id}
            initialOption={option}
            vendor={vendor}
            setVendor={setVendor}
            products={products}
          />
        ))}
    </div>
  );
};

export default DeliveryOptions;
