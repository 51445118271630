import React from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Typography,
} from "@material-ui/core";

import { redirectStripeConnectOnboarding, updatePaymentMethods } from "../api";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "24px 0 16px 36px",
    [theme.breakpoints.down("sm")]: {
      margin: "24px 8px 16px 36px",
    },
  },
  statusContainer: {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(1),
      flexDirection: "column",
      alignItems: "stretch",
    },
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(2, 0),
  },
  checkbox: {
    padding: "4px 9px",
  },
  success: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  },
}));

const StripeConnectContainer = ({
  vendorStripeAccount,
  paymentMethods,
  handleStripeBooleanChange,
  needsMigration,
}) => {
  const classes = useStyles();

  const renderStatus = () => {
    if (vendorStripeAccount.details_submitted) {
      return <span className={classes.success}>Connected</span>;
    }
    if (vendorStripeAccount.details_submitted === false) {
      return <span className={classes.error}>Incomplete</span>;
    }
    return <span className={classes.error}>Not Connected</span>;
  };

  const setUpStripe = () => {
    updatePaymentMethods({ payment_methods: [paymentMethods.ST] }).then(() =>
      redirectStripeConnectOnboarding(),
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.statusContainer}>
        <Typography variant="body1" style={{ fontWeight: 500 }}>
          Status: {renderStatus()}
        </Typography>
        {vendorStripeAccount.details_submitted ? (
          <Button
            variant="contained"
            size="small"
            href="https://dashboard.stripe.com"
            target="_blank"
            referrer="noreferrer"
          >
            Manage
          </Button>
        ) : (
          <Button variant="contained" color="primary" size="small" onClick={setUpStripe}>
            Set Up
          </Button>
        )}
      </div>
      {vendorStripeAccount.details_submitted && needsMigration && (
        <div className={classes.statusContainer}>
          <Typography variant="body1" style={{ fontWeight: 500 }} className={classes.error}>
            Please migrate your stripe account to avoid disruptions!
          </Typography>
          <Button variant="contained" color="primary" size="small" onClick={setUpStripe}>
            Migrate
          </Button>
        </div>
      )}
      <FormGroup className={classes.checkboxContainer}>
        <FormControlLabel
          control={
            <Checkbox
              className={classes.checkbox}
              size="small"
              checked={paymentMethods.ST.is_stripe_credit_card_enabled}
              onChange={handleStripeBooleanChange}
              name="is_stripe_credit_card_enabled"
              color="primary"
            />
          }
          label={
            <Typography variant="body2">
              Accept Credit Card <i>(Fee: 3.4% + $0.50)</i>
            </Typography>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              className={classes.checkbox}
              size="small"
              checked={paymentMethods.ST.is_stripe_paynow_enabled}
              onChange={handleStripeBooleanChange}
              name="is_stripe_paynow_enabled"
              color="primary"
            />
          }
          label={
            <Typography variant="body2">
              Accept Automated PayNow <i>(Fee: 1.3%)</i>
            </Typography>
          }
        />
      </FormGroup>
    </div>
  );
};

export default StripeConnectContainer;
