import React, { useState } from "react";
import { Chip, IconButton, makeStyles, Menu, MenuItem, Paper, Typography } from "@material-ui/core";
import { DragHandle, MoreVert } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    display: "flex",
    gap: theme.spacing(1),
    padding: theme.spacing(1.5),
    margin: theme.spacing(0.5, 0),
  },
  leftContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: theme.spacing(1),
  },
  rightContainer: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
  },
  greyText: {
    color: theme.palette.grey[700],
  },
}));

const TextElement = ({ element, classes }) => {
  return (
    <>
      <Chip variant="outlined" size="small" label="Text" color="secondary" />
      <Typography variant="body1">{element.title}</Typography>
      {element?.long_description && (
        <Typography variant="body2" className={classes.greyText}>
          {element.long_description.length > 40
            ? element.long_description.substring(0, 40) + "..."
            : element.long_description}
        </Typography>
      )}
    </>
  );
};

const ExternalLinkButtonElement = ({ element, classes }) => {
  return (
    <>
      <Chip variant="outlined" size="small" label="Link Button" color="secondary" />
      <Typography variant="body1">
        {element.title} &rarr;{" "}
        <span style={{ wordBreak: "break-all" }} className={classes.greyText}>
          {element?.link}
        </span>
      </Typography>
    </>
  );
};

const InternalLinkButtonElement = ({ element, classes }) => {
  return (
    <>
      <Chip variant="outlined" size="small" label="Shop Link" color="secondary" />
      <Typography variant="body1">
        {element.title} &rarr;{" "}
        <span style={{ wordBreak: "break-all" }} className={classes.greyText}>
          {element?.route}
        </span>
      </Typography>
    </>
  );
};

const ElementCard = ({ element, provided, selectElementForEdit, selectElementForDelete, setDeleteDialogOpen }) => {
  const classes = useStyles();

  const [anchor, setAnchor] = useState(null);

  const renderBody = () => {
    switch (element?.element_type) {
      case "Text":
        return <TextElement element={element} classes={classes} />;
      case "Link":
        return <ExternalLinkButtonElement element={element} classes={classes} />;
      case "Route":
        return <InternalLinkButtonElement element={element} classes={classes} />;
      default:
        return null;
    }
  };

  return (
    <Paper className={classes.paperRoot} ref={provided.innerRef} {...provided.draggableProps}>
      <div className={classes.leftContainer}>{renderBody()}</div>
      <div className={classes.rightContainer}>
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          size="small"
          onClick={(e) => setAnchor(e.currentTarget)}
        >
          <MoreVert fontSize="small" />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchor}
          keepMounted
          open={Boolean(anchor)}
          onClose={() => setAnchor(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          getContentAnchorEl={null}
        >
          <MenuItem
            onClick={() => {
              selectElementForEdit();
              setAnchor(null);
            }}
          >
            Edit Element
          </MenuItem>
          {element.element_type !== "Route" && (
            <MenuItem
              onClick={() => {
                selectElementForDelete();
                setDeleteDialogOpen(true);
                setAnchor(null);
              }}
            >
              Delete Element
            </MenuItem>
          )}
        </Menu>
        <div {...provided.dragHandleProps} style={{ display: "flex", alignItems: "center" }}>
          <DragHandle />
        </div>
      </div>
    </Paper>
  );
};

export default ElementCard;
