import React, { useState, useEffect } from "react";
import { ColorPicker, createColor } from "material-ui-color";
import { useDebouncedCallback } from "use-debounce";

const DebouncedPicker = ({ initialColor, color, setColor }) => {
  const [value, setValue] = useState(createColor(initialColor));

  useEffect(() => {
    setValue(createColor(color));
  }, [color]);

  const debounce = useDebouncedCallback((color) => {
    setColor(`#${color.hex}`);
  }, 300);

  return (
    <div>
      <ColorPicker
        disableAlpha
        hideTextfield
        value={value}
        onChange={(color) => {
          setValue(color);
          debounce(color);
        }}
      />
    </div>
  );
};

export default DebouncedPicker;
