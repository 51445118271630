import Auth from "../../../Auth";
import { BACKEND_URL, API_VERSION } from "../../../config";

export const fetchProducts = (queryParams) => {
  return Auth.client.get(`${BACKEND_URL}/${API_VERSION}/products`, { params: queryParams });
};

export const updateProductStatus = (product, changeActivationOfProduct) => {
  return Auth.client.patch(`${BACKEND_URL}/${API_VERSION}/products/${product.product_id}`, changeActivationOfProduct);
};

export const getAllProducts = () => {
  return Auth.client.get(`${BACKEND_URL}/${API_VERSION}/products`, { params: { dump: 1 } });
};

export const reorderProducts = (productIds) => {
  return Auth.client.patch(`${BACKEND_URL}/${API_VERSION}/products`, productIds);
};

export const scheduleProductsRelease = (scheduleReleaseObj) => {
  return Auth.client.patch(`${BACKEND_URL}/${API_VERSION}/schedule-product-release`, scheduleReleaseObj);
};

export const getProductCategories = () => {
  return Auth.client.get(`${BACKEND_URL}/${API_VERSION}/product-categories`);
};

export const addProductCategory = (categoryName) => {
  return Auth.client.post(`${BACKEND_URL}/${API_VERSION}/product-categories`, {
    product_category_name: categoryName,
    products: [],
  });
};

export const updateProductCategory = (updatedCategoryObj) => {
  return Auth.client.put(
    `${BACKEND_URL}/${API_VERSION}/product-categories/${updatedCategoryObj.product_category_id}`,
    updatedCategoryObj
  );
};

export const deleteProductCategory = (selectedCategory) => {
  return Auth.client.delete(`${BACKEND_URL}/${API_VERSION}/product-categories/${selectedCategory.product_category_id}`);
};

export const reorderProductCategories = (categoryIds) => {
  return Auth.client.patch(`${BACKEND_URL}/${API_VERSION}/product-categories`, categoryIds);
};
