export const PREMIUM_PLANS = [
  {
    value: "sgd",
    title: "S$10",
    subtitle: "/month",
  },
  {
    value: "usd",
    title: "US$7",
    subtitle: "/month",
  },
];
