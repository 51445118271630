import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Link } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import { isEmptyObj } from "../../utils";

const useStyles = makeStyles((theme) => ({
  alert: {
    margin: theme.spacing(1, 0),
    maxWidth: 960,
  },
}));

const PaymentSettingsAlert = () => {
  const { user: vendor } = useAuth();
  const classes = useStyles();

  if (isEmptyObj(vendor)) {
    return <React.Fragment />;
  }

  const paymentMethodExists = vendor.payment_methods.reduce((prev, pm) => prev || pm.is_enabled, false);

  if (!paymentMethodExists) {
    return (
      <Alert
        className={classes.alert}
        severity="error"
        action={
          <Button component={Link} to="/home/settings/payments" color="inherit" size="small">
            Settings
          </Button>
        }
      >
        No payment method found, go to your settings to set up a payment method
      </Alert>
    );
  }

  const paymentMethods = vendor.payment_methods.reduce((prev, pm) => ({ ...prev, [pm.payment_method]: pm }), {});

  if (paymentMethods.PN && !paymentMethods.PN.uen && !paymentMethods.PN.paynow_mobile) {
    return (
      <Alert
        className={classes.alert}
        severity="error"
        action={
          <Button component={Link} to="/home/settings/payments" color="inherit" size="small">
            Settings
          </Button>
        }
      >
        No PayNow details found, go to your settings to set up PayNow payments
      </Alert>
    );
  }
  return <React.Fragment />;
};

export default PaymentSettingsAlert;
