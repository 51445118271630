import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import LoginPage from "./vendor/LoginPage";
import RegisterPage from "./vendor/RegisterPage";
import PrivateRoute from "./components/PrivateRoute";
import NotFound from "./vendor/NotFound";
import HomePage from "./vendor/HomePage/HomePage";
import VerifiedEmail from "./vendor/VerifiedEmail";
import ResetPasswordPage from "./vendor/ResetPasswordPage";

const App = () => {
  return (
    <Switch>
      {/* <Route exact path="/test" component={Test} /> */}
      <Route exact path="/verify/:id" component={VerifiedEmail} />
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/register" component={RegisterPage} />
      <Route exact path="/reset-password" component={ResetPasswordPage} />
      <Route exact path="/404" component={NotFound} />
      <PrivateRoute path="/home" render={() => <HomePage />} />
      <Redirect from="/" to="/home" />
      <Route component={NotFound} />
    </Switch>
  );
};

export default App;
