import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, TextField, Switch, Button, IconButton, FormHelperText, Divider } from "@material-ui/core";
import { DropzoneAreaBase } from "material-ui-dropzone";
import { Delete } from "@material-ui/icons";

import DebouncedPicker from "./DebouncedPicker";
import GenericContainer from "../../../components/containers/GenericContainer";
import useShopContext from "../../../hooks/useShopContext";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 400,
    margin: theme.spacing(1, 0, 1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subheader: {
    marginBottom: theme.spacing(1),
  },
  textField: {
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  colorPickerContainer: {
    display: "flex",
  },
  colorPicker: {
    height: 100,
  },
  dropzoneContainer: {
    margin: theme.spacing(1, 0),
    maxWidth: 220,
    minHeight: 70,
    height: 110,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "none",
      height: 95,
    },
  },
  dropzoneParagraph: {
    fontSize: 14,
    padding: theme.spacing(0, 1),
  },
  imageItem: {
    marginTop: theme.spacing(2),
    height: "120px",
    position: "relative",
    width: "fit-content",
  },
  image: {
    objectFit: "contain",
    height: "120px",
  },
  deleteBtn: {
    position: "absolute",
    top: -12,
    right: -12,
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  toggleSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const ShopLanding = () => {
  const classes = useStyles();
  const { shopfront, setShopfront } = useShopContext();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShopfront({ ...shopfront, [name]: value });
  };

  const handleAddImage = (images) => {
    setShopfront({ ...shopfront, background_image: images[0] });
  };

  const opacityError = shopfront.text_background_opacity < 0 || shopfront.text_background_opacity > 1;

  return (
    <GenericContainer>
      <Typography variant="h5" className={classes.header}>
        Landing Page
        <Switch
          style={{ float: "right" }}
          checked={shopfront.use_welcome_page}
          onChange={(e) =>
            setShopfront({
              ...shopfront,
              use_welcome_page: e.target.checked,
            })
          }
          color="primary"
        />
      </Typography>

      {shopfront.use_welcome_page && (
        <>
          <Typography variant="body2" color="textSecondary" className={classes.subheader}>
            Displayed when your customers land on your shop
          </Typography>

          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography>Text Color: </Typography>
            <DebouncedPicker
              initialColor={shopfront.text_color}
              color={shopfront.text_color}
              setColor={(color) => setShopfront({ ...shopfront, text_color: color })}
            />
            <FormHelperText>For better readability</FormHelperText>
          </div>

          <Divider className={classes.divider} />

          <div className={classes.toggleSection}>
            <Typography>Display Reviews</Typography>
            <Switch
              checked={shopfront.display_reviews}
              onChange={(e) => setShopfront({ ...shopfront, display_reviews: e.target.checked })}
              color="primary"
            />
          </div>

          <Divider className={classes.divider} />

          <div className={classes.toggleSection}>
            <Typography>Add Background Image</Typography>
            <Switch
              checked={shopfront.use_background_image}
              onChange={(e) =>
                setShopfront({
                  ...shopfront,
                  background_image: e.target.checked ? shopfront.background_image : null,
                  use_background_image: e.target.checked,
                })
              }
              color="primary"
            />
          </div>

          {shopfront.use_background_image && (
            <div style={{ marginBottom: "8px" }}>
              <TextField
                variant="outlined"
                autoComplete="off"
                name="text_background_opacity"
                label={<span style={{ whiteSpace: "nowrap" }}>Text Background Opacity</span>}
                type="number"
                inputProps={{
                  min: 0,
                  step: 0.01,
                }}
                onWheel={(event) => event.target.blur()}
                classes={{ root: classes.textField }}
                onChange={handleInputChange}
                value={shopfront.text_background_opacity}
                error={opacityError}
              />
              {opacityError && <FormHelperText error>Value should be between 0.0 and 1.0</FormHelperText>}
              {shopfront.background_image ? (
                <div className={classes.imageItem}>
                  <img
                    className={classes.image}
                    src={shopfront.background_image.data ? shopfront.background_image.data : shopfront.background_image}
                    alt="background"
                  />
                  <IconButton
                    disableRipple
                    size="small"
                    className={classes.deleteBtn}
                    onClick={() => setShopfront({ ...shopfront, background_image: null })}
                  >
                    <Delete />
                  </IconButton>
                </div>
              ) : (
                <DropzoneAreaBase
                  dropzoneText={
                    <>
                      Drop image here or{" "}
                      <Button variant="contained" color="primary" style={{ color: "#fff" }}>
                        Upload Image
                      </Button>
                    </>
                  }
                  dropzoneParagraphClass={classes.dropzoneParagraph}
                  dropzoneClass={classes.dropzoneContainer}
                  acceptedFiles={["image/*"]}
                  filesLimit={1}
                  onAdd={(newPhoto) => handleAddImage(newPhoto)}
                  showPreviewsInDropzone={false}
                  maxFileSize={10000000} // allow up to 10MB
                />
              )}
            </div>
          )}
        </>
      )}
    </GenericContainer>
  );
};

export default ShopLanding;
