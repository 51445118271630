import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Typography } from "@material-ui/core";
import StyledTypography from "../components/StyledTypography";

const styles = (theme) => ({
  root: {
    height: "100vh",
    backgroundColor: theme.palette.background.dark,
    display: "grid",
    placeItems: "center",
  },
  paperRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    gap: theme.spacing(1),
    margin: theme.spacing(1),
    padding: theme.spacing(4, 2),
    minHeight: "60vh",
    width: "40vw",
    [theme.breakpoints.down("sm")]: {
      width: "85vw",
    },
  },
});

const ActivationEmail = (props) => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Paper elevation={3} className={classes.paperRoot}>
        <img src="/email-v2.png" style={{ display: "block", maxWidth: 320 }} alt="email activation" />
        <Typography variant="h1">
          <StyledTypography>Welcome onboard!</StyledTypography>
        </Typography>
        <Typography variant="body1">An email has been sent to you for verification!</Typography>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(ActivationEmail);
