import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    margin: theme.spacing(0, 1),
    color: theme.palette.error.main,
  },
}));

const DeleteElementDialog = ({ open, setOpen, handleDelete, setElement }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      TransitionProps={{
        onExited: () =>
          setElement({
            title: "",
            order: 0,
            element_type: "",
            image: [],
          }),
      }}
    >
      <DialogTitle>Delete Landing Page Element</DialogTitle>
      <DialogContent>This action cannot be undone, are you sure you want to delete?</DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button onClick={() => handleDelete()} className={classes.deleteButton}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteElementDialog;
