import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import PageTitle from "../../components/PageTitle";
import SubscriptionDetails from "./components/SubscriptionDetails";
import useAuth from "../../hooks/useAuth";
import { useSnackbar } from "notistack";

import { checkCheckoutSession, upgradeVendorToPremium } from "./api";
import ReferralDetails from "./components/ReferralDetails";
import ReferralHistory from "./components/ReferralHistory";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 190px)", // padding: 30px * 2, title: 80px + 50px, total 190px
    margin: 30,
  },
}));

const Subscription = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { user: vendor, refresh } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [subscribed, setSubscribed] = useState(false);

  // const [rows, setRows] = useState([]);
  // const [pageNum, setPageNum] = useState(1);
  // const [totalCount, setTotalCount] = useState(0);

  // const [subscriptionDialog, setSubscriptionDialog] = useState(false);

  useEffect(() => {
    // retrieve data from database and see if vendor is currently subscribed
    const completeStripeParams = new URLSearchParams(location.search).get("sessionid");

    if (completeStripeParams) {
      checkCheckoutSession(completeStripeParams)
        .then((res) => {
          // console.log(res);
          upgradeVendorToPremium(res.data.cust_id).then((res) => {
            refresh();
            history.push("/home/subscription");
          });
        })
        .catch((err) => {
          setLoading(false);
          // console.log(err);
          enqueueSnackbar(err.response.data, { variant: "error" });
        });
    }

    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const completeStripeParams = new URLSearchParams(location.search).get("sessionid");

    // only remove loader after updating database and a change in vendor's fields
    if (!completeStripeParams) {
      if (Object.keys(vendor).length > 0 && vendor.permissions && vendor.permissions.includes("premium")) {
        setSubscribed(true);
      }
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendor]);

  return (
    <div className={classes.root}>
      <PageTitle title="Premium Subscription" />
      <SubscriptionDetails subscribed={subscribed} loading={loading} />
      <ReferralDetails />
      <ReferralHistory />
    </div>
  );
};

export default Subscription;
