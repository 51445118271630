import Auth from "../../../Auth";
import { BACKEND_URL, API_VERSION } from "../../../config";

export const checkoutToSubscribe = (promoCode, plan = "sgd") => {
  return Auth.client
    .get(`${BACKEND_URL}/${API_VERSION}/stripe-create-subscription-checkout`, {
      params: { promo_code: promoCode, plan: plan },
    })
    .then((res) => window.location.assign(res.data.redirect));
};

export const checkCheckoutSession = (sessionId) => {
  return Auth.client.get(`${BACKEND_URL}/${API_VERSION}/stripe-retrieve-checkout-session`, {
    params: { session_id: sessionId },
  });
};

export const upgradeVendorToPremium = (customerId) => {
  return Auth.client.patch(`${BACKEND_URL}/${API_VERSION}/vendor-subscription`, {
    cust_id: customerId,
  });
};

export const viewSubscription = () => {
  return Auth.client
    .get(`${BACKEND_URL}/${API_VERSION}/stripe-customer-portal`)
    .then((res) => window.location.assign(res.data.redirect));
};

export const viewSubscriptionOld = () => {
  return Auth.client
    .get(`${BACKEND_URL}/${API_VERSION}/stripe-customer-portal-old`)
    .then((res) => window.location.assign(res.data.redirect));
};

export const applyPromoToExistingPremium = (promoCode) => {
  return Auth.client.post(`${BACKEND_URL}/${API_VERSION}/stripe-vendor-apply-promo`, {
    promo_code: promoCode,
  });
};
