export const parseCartItems = (cartItems = []) => {
  const cartItemStr = cartItems.reduce((prevCartItems, cartItem) => {
    if (!cartItem.product) {
      return prevCartItems;
    }

    let str = `${cartItem.product.title} $${cartItem.product.price} (Qty: ${cartItem.quantity})\n`;
    if (cartItem.meta) {
      str += "Options:\n";
      let metaStr = cartItem.meta.reduce((prev, metaQn) => {
        // parse responses
        let responsesStr = metaQn.responses.reduce((prevR, currR) => {
          return `${prevR}      - ${currR}\n`;
        }, "");

        return `${prev} - ${metaQn.question_title}\n${responsesStr}\n`;
      }, "");

      str += metaStr;
    }
    return `${prevCartItems}${str}\n`;
  }, "");

  return `"${cartItemStr}"`;
};
