import Auth from "../../../Auth";
import { BACKEND_URL, API_VERSION } from "../../../config";

export const createElement = (elementFormData, shopfrontId) => {
  return Auth.client.post(`${BACKEND_URL}/${API_VERSION}/shopfronts/${shopfrontId}/elements`, elementFormData);
};

export const editElement = (elementFormData, elementId, shopfrontId) => {
  return Auth.client.put(
    `${BACKEND_URL}/${API_VERSION}/shopfronts/${shopfrontId}/elements/${elementId}`,
    elementFormData
  );
};

export const deleteElement = (element, shopfrontId) => {
  return Auth.client.delete(`${BACKEND_URL}/${API_VERSION}/shopfronts/${shopfrontId}/elements/${element.element_id}`);
};

export const reorderElements = (elementIdsArr, shopfrontId) => {
  return Auth.client.patch(`${BACKEND_URL}/${API_VERSION}/shopfronts/${shopfrontId}/elements`, elementIdsArr);
};

export const updateVendorLogo = (image) => {
  let formData = new FormData();
  formData.set("company_photo", image);
  return Auth.client.put(`${BACKEND_URL}/${API_VERSION}/vendor-logo`, formData);
};

export const deleteVendorLogo = () => {
  let formData = new FormData();
  formData.append("company_photo", "");
  return Auth.client.put(`${BACKEND_URL}/${API_VERSION}/vendor-logo`, formData);
};

export const updateUserSettings = (user) => {
  const updatedDetails = {
    subdomain: user.subdomain,
    tnc: user.tnc,
    enable_seasonal_features: user.enable_seasonal_features,
  };
  return Auth.client.patch(`${BACKEND_URL}/${API_VERSION}/vendors`, updatedDetails);
};
