import React, { useState, useEffect } from "react";
import { makeStyles, Typography, Grid, Switch, TextField, Button } from "@material-ui/core";
import { ForumTwoTone } from "@material-ui/icons";
import { useSnackbar } from "notistack";

import GenericContainer from "../../../components/containers/GenericContainer";

import { updateVendorEngagementSettings } from "../api";
import useAuth from "../../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 400,
    margin: theme.spacing(1, 0),
  },
  headerDescription: {
    marginBottom: "12px",
  },
  gridItem: {
    padding: theme.spacing(1.5, 0),
  },
  settingsIcon: {
    marginRight: theme.spacing(1.5),
  },
  leftMargin: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(4.5),
  },
  saveButton: {
    width: 150,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
}));

const EngagementSettings = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { user, refresh } = useAuth();

  const [vendor, setVendor] = useState(user);

  const updateVendorDetails = () => {
    updateVendorEngagementSettings(vendor)
      .then((vendor) => {
        enqueueSnackbar("Settings updated successfully!", { variant: "success" });
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Failed to update details!", { variant: "error" });
      })
      .finally(() => refresh());
  };

  useEffect(() => {
    setVendor(user);
  }, [user]);

  return (
    <GenericContainer>
      <Typography variant="h5" className={classes.header}>
        Engagement Settings
      </Typography>
      <Typography variant="body1" color="textSecondary" className={classes.headerDescription}>
        Customize how you would like to engage with your customers
      </Typography>
      <Grid container>
        <Grid item xs={12} className={classes.gridItem}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ForumTwoTone className={classes.settingsIcon} />
            <Typography variant="h6" style={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
              <b>Special Requests</b>
            </Typography>
            <Switch
              color="primary"
              checked={vendor.use_special_request}
              onChange={(e) =>
                setVendor({
                  ...vendor,
                  use_special_request: e.target.checked,
                })
              }
            />
          </div>
          <div className={classes.leftMargin}>
            <Typography variant="body2" style={{ marginBottom: 8 }}>
              Allow customers to add special requests during checkout
            </Typography>
            {vendor.use_special_request && (
              <TextField
                margin="dense"
                variant="outlined"
                fullWidth
                autoComplete="off"
                type="text"
                label="Custom Prompt (Optional)"
                multiline
                rows={3}
                value={vendor.special_request_description}
                onChange={(e) =>
                  setVendor({
                    ...vendor,
                    special_request_description: e.target.value,
                  })
                }
              />
            )}
          </div>
        </Grid>

        <Grid item xs={12} className={classes.gridItem}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ForumTwoTone className={classes.settingsIcon} />
            <Typography variant="h6" style={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
              <b>Checkout Message</b>
            </Typography>
            <Switch
              color="primary"
              checked={vendor.use_checkout_message}
              onChange={(e) =>
                setVendor({
                  ...vendor,
                  use_checkout_message: e.target.checked,
                })
              }
            />
          </div>
          <div className={classes.leftMargin}>
            <Typography variant="body2" style={{ marginBottom: 8 }}>
              Personalize your checkout message
            </Typography>
            {vendor.use_checkout_message && (
              <TextField
                margin="dense"
                variant="outlined"
                fullWidth
                autoComplete="off"
                type="text"
                label="Message"
                multiline
                rows={3}
                value={vendor.checkout_message}
                onChange={(e) =>
                  setVendor({
                    ...vendor,
                    checkout_message: e.target.value,
                  })
                }
              />
            )}
          </div>
        </Grid>
      </Grid>

      <div className={classes.buttonContainer}>
        <Button variant="contained" color="primary" className={classes.saveButton} onClick={updateVendorDetails}>
          Save
        </Button>
      </div>
    </GenericContainer>
  );
};

export default EngagementSettings;
