import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  Card,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useSnackbar } from "notistack";

import useOrdersContext from "../../../hooks/useOrdersContext";

import { statuses, statusMap, statusColors } from "../helpers/utils";
import { batchUpdateOrderStatus } from "../api";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "60%",
    maxHeight: "90vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  dialogHeader: {
    margin: theme.spacing(1, 0),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1, 3),
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(0, 1),
    width: 150,
  },
  denseSelect: {
    padding: theme.spacing(1),
  },
  orderContainer: {
    flex: 1,
    overflow: "hidden",
    overflowY: "auto",
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    backgroundColor: theme.palette.grey[200],
    borderRadius: 4,
  },
  loadingContainer: {
    width: "100%",
    display: "grid",
    placeItems: "center",
    padding: theme.spacing(2),
  },
  alert: {
    margin: theme.spacing(1),
  },
  cardRoot: {
    overflow: "visible",
    padding: theme.spacing(2, 3),
  },
  label: {
    fontSize: 14,
  },
}));

const UpdateStatusDialog = ({ open, setOpen, rows, selectedRows }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { refreshOrders } = useOrdersContext();

  const [status, setStatus] = useState("PAID");
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);

  const [submitLoading, setSubmitLoading] = useState(false);

  const handleUpdate = () => {
    setSubmitLoading(true);
    batchUpdateOrderStatus({ status: status, transactionIds: selectedRows })
      .then((res) => {
        setOpen(false);
        enqueueSnackbar("Orders updated", { variant: "success" });
        refreshOrders();
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data, { variant: "error" });
      })
      .finally(() => setSubmitLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    setOrders(rows.filter((row) => selectedRows.includes(row.id)));
    setLoading(false);
  }, [rows, selectedRows]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} classes={{ paper: classes.paper }}>
      <div className={classes.dialogHeader}>
        <Typography variant="h4" style={{ fontWeight: 500 }}>
          Update status
        </Typography>
        <IconButton size="small" onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </div>
      <DialogContent className={classes.dialogContent}>
        <FormControl variant="outlined" style={{ width: "100%" }}>
          <Select
            id="status-select"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            classes={{ outlined: classes.denseSelect }}
          >
            <MenuItem disabled value="UNCOMPLETED">
              Uncompleted
            </MenuItem>
            <MenuItem value="PENDING">Pending</MenuItem>
            <MenuItem value="PENDING_VERIFICATION">Pending Verification</MenuItem>
            <MenuItem value="PAID">Paid</MenuItem>
            <MenuItem value="PENDING_DELIVERY">Pending Delivery</MenuItem>
            <MenuItem value="COMPLETED">Completed</MenuItem>
            <MenuItem disabled value="FAILED">
              Failed
            </MenuItem>
          </Select>
        </FormControl>

        {status === "PAID" && (
          <Alert className={classes.alert} severity="info">
            <AlertTitle>
              An <b>invoice email</b> will be sent out with this status update.
            </AlertTitle>
            To prevent spam, we will only send up to <strong>5</strong> emails (including delivery notifications) per
            order.
          </Alert>
        )}

        {status === "COMPLETED" && (
          <Alert className={classes.alert} severity="info">
            <AlertTitle>
              A <b>review request email</b> will be sent out with this status update.
            </AlertTitle>
            To prevent spam, we will only send up to <strong>5</strong> emails (including delivery notifications) per
            order.
          </Alert>
        )}

        {loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        ) : (
          <Fragment>
            <Typography variant="body2" color="textSecondary">
              {orders.length} item(s) selected
            </Typography>
            <div className={classes.orderContainer}>
              {orders.map((row) => (
                <Card key={row.id} className={classes.cardRoot} variant="outlined">
                  <Typography className={classes.label} color="textSecondary">
                    ID
                  </Typography>
                  <Typography style={{ fontWeight: 500 }} gutterBottom>
                    {row.public_transaction_id}
                  </Typography>
                  <Typography className={classes.label} color="textSecondary">
                    Amount
                  </Typography>
                  <Typography gutterBottom>${row.total_price}</Typography>
                  <Typography className={classes.label} color="textSecondary">
                    Status
                  </Typography>
                  <Typography gutterBottom style={{ color: statusColors[statuses.indexOf(statusMap[row.status])] }}>
                    {row.status}
                  </Typography>
                  <Typography className={classes.label} variant="body2">
                    <i>Email limit: {row.email_quota}/5</i>
                  </Typography>
                </Card>
              ))}
            </div>
          </Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleUpdate()}
          className={classes.button}
          color="primary"
          variant="contained"
          disabled={selectedRows.length <= 0 || submitLoading}
        >
          {submitLoading ? <CircularProgress size={24} /> : "Update"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateStatusDialog;
