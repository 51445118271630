import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";

const DeleteCategoryDialog = ({ deleteDialog, setDeleteDialog, deleteCategory }) => {
  return (
    <Dialog open={deleteDialog} onClose={() => setDeleteDialog(false)}>
      <DialogTitle>Delete Category?</DialogTitle>
      <DialogContent>This action cannot be undone, are you sure you want to delete?</DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setDeleteDialog(false)}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={() => deleteCategory()}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCategoryDialog;
